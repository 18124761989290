import React, { useState } from "react";
import { FaArrowLeft } from "react-icons/fa";
import profile_image4 from "../../assets/profile-img5.jpeg";
import { FaExclamationCircle } from "react-icons/fa";

import { useParams, useNavigate } from "react-router-dom";
import { FaRegImage } from "react-icons/fa6";
import { RiSendPlaneFill } from "react-icons/ri";
import { friends } from "../../Components/Common/Data";

const ChatDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const friend = friends.find((f) => f.id == id);
  console.log("friend", friend);
  return (
    <div className="">
      <div className="flex justify-between items-center py-5 pt-6 px-5 bg-red-50">
        <div className="flex justify-normal items-center gap-2">
          <FaArrowLeft className="text-xl" onClick={() => navigate(-1)} />
          <div className="h-[40px] w-[40px] rounded-full bg-[#222222] ml-3">
            <img
              src={friend?.image || profile_image4}
              alt=""
              className="h-full w-full object-fill rounded-full"
            />
          </div>
          <p className="text-[18px] font-medium">{friend?.name}</p>
        </div>
        <FaExclamationCircle className="text-3xl text-[#CB445C]" />
      </div>
      <div className="h-[800px] overflow-y-auto">
        <div className="card mx-4 mt-6 pb-10">
          {/* 1 */}
          <div>
            <div className="chat chat-start">
              <div className="chat-image avatar">
                <div className="w-10 rounded-full">
                  <img
                    alt="Tailwind CSS chat bubble component"
                    src={profile_image4}
                  />
                </div>
              </div>

              <div className="chat-bubble !bg-[#f3f3f3] !text-gray-600">
                You were the Chosen One!
              </div>
              <div className="chat-footer opacity-50">Delivered</div>
            </div>
            <div className="chat chat-end">
              <div className="chat-bubble !bg-[#CB445C] !text-white">
                I hate you!
              </div>
              <div className="chat-footer opacity-50">Seen at 12:46</div>
            </div>
          </div>
          <div>
            <p className="text-gray-300 text-center py-1">SUN AT 8.28 PM</p>
          </div>
          {/* 2 */}
          <div>
            <div className="chat chat-start">
              <div className="chat-image avatar">
                <div className="w-10 rounded-full">
                  <img
                    alt="Tailwind CSS chat bubble component"
                    src={profile_image4}
                  />
                </div>
              </div>

              <div className="chat-bubble !bg-[#f3f3f3] !text-gray-600">
                You were the Chosen One!
              </div>
              <div className="chat-bubble !bg-[#f3f3f3] !text-gray-600 mt-0.5">
                It was said that you would, destroy the Sith, not join them.
              </div>
              <div className="chat-footer opacity-50">Delivered</div>
            </div>
            <div className="chat chat-end">
              <div className="chat-bubble !bg-[#CB445C] !text-white">
                To be on the Council at your age.
              </div>
              <div className="chat-footer opacity-50">Seen at 12:46</div>
            </div>
          </div>
          {/* 3 */}
          <div>
            <div className="chat chat-start">
              <div className="chat-image avatar">
                <div className="w-10 rounded-full">
                  <img
                    alt="Tailwind CSS chat bubble component"
                    src={profile_image4}
                  />
                </div>
              </div>

              <div className="chat-bubble !bg-[#f3f3f3] !text-gray-600">
                It was you who would bring balance to the Force. Not leave it in
                Darkness
              </div>
              <div className="chat-bubble !bg-[#f3f3f3] !text-gray-600 mt-0.5">
                It was said that you would, destroy the Sith, not join them.
              </div>
              <div className="chat-footer opacity-50">Delivered</div>
            </div>
            <div className="chat chat-end">
              <div className="chat-bubble !bg-[#CB445C] !text-white">
                You given a great honor.
              </div>
              <div className="chat-bubble !bg-[#CB445C] !text-white mt-0.5">
                I hate you!
              </div>
              <div className="chat-footer opacity-50">Seen at 12:46</div>
            </div>
          </div>
          {/* 4 */}
          <div>
            <div className="chat chat-start">
              <div className="chat-image avatar">
                <div className="w-10 rounded-full">
                  <img
                    alt="Tailwind CSS chat bubble component"
                    src={profile_image4}
                  />
                </div>
              </div>

              <div className="chat-bubble !bg-[#f3f3f3] !text-gray-600">
                You were the Chosen One!
              </div>
              <div className="chat-footer opacity-50">Delivered</div>
            </div>
            <div className="chat chat-end">
              <div className="chat-bubble !bg-[#CB445C] !text-white">
                I hate you!
              </div>
              <div className="chat-footer opacity-50">Seen at 12:46</div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="absolute bottom-0 left-0 w-[100%]"
        style={{
          background: "#fcf3fe",
          boxShadow: "-5px 5px -5px #dc354578 !important",
        }}>
        <div className="py-5 px-6">
          <div className="flex justify-normal items-center gap-3">
            <FaRegImage className="text-4xl text-[#CB445C]" />
            <input
              type="text"
              placeholder="Message"
              className="input rounded-full border border-[#efa0ae] w-full px-2 h-11"
            />
            <RiSendPlaneFill className="text-4xl text-blue-600" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChatDetails;
