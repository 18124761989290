import React from "react";
import DefaultLayout from "../Components/Common/DefaultLayout";
import { IoBookOutline } from "react-icons/io5";
import { IoIosArrowForward } from "react-icons/io";
import { Link } from "react-router-dom";
import CommonHeader from "../Components/Common/CommonHeader";
import CommonRadio from "../Components/Common/CommonRadio";

const Religious = () => {
  return (
    <DefaultLayout>
      <div className="h-10 w-10 rounded-full border border-gray-600 flex justify-center items-center mt-10">
        <IoBookOutline className="text-xl" />
      </div>

      <div className="flex justify-between items-start gap-1 w-[100%]">
        <CommonHeader title={"What's your religious status?"} />
        <div className="skip">
          <Link to="/political" className="">
            Skip Now
          </Link>
        </div>
      </div>

      <div className="mt-8">
        <CommonRadio title="Christianity" />
        <CommonRadio title="Islam" />
        <CommonRadio title="Hinduism" />
        <CommonRadio title="Buddhism" />
        <CommonRadio title="Judaism" />
        <CommonRadio title="Sikhism" />
      </div>
      <div className="flex justify-normal items-center gap-1 mt-14">
        <input type="checkbox" className="checkbox" />
        <p>Visible on profile</p>
      </div>
      <div className="mt-14">
        <p>
          <span className="text-[#cb445c] font-semibold">Learn more </span>{" "}
          about how we use religious to recommended people.
        </p>
      </div>
      <Link to="/political" className="absolute bottom-10 right-8">
        <div className="h-14 w-14 rounded-full border border-[#CB445C] bg-[#CB445C] flex justify-center items-center">
          <IoIosArrowForward className="text-white text-2xl" />
        </div>
      </Link>
    </DefaultLayout>
  );
};

export default Religious;
