import React from "react";
import { IoIosArrowForward } from "react-icons/io";
import { Link } from "react-router-dom";
import { MdOutlineVerifiedUser } from "react-icons/md";
import DefaultLayout from "../Components/Common/DefaultLayout";
import CommonHeader from "../Components/Common/CommonHeader";

const EmailOTP = () => {
  return (
    <DefaultLayout>
      <div className="h-10 w-10 rounded-full border border-gray-600 flex justify-center items-center mt-10">
        <MdOutlineVerifiedUser className="text-xl" />
      </div>
      <CommonHeader title={"Enter your verification code"} />
      <div className="flex justify-center items-center gap-3 mt-7">
        <input
          type="number"
          className="input border-0 border-b px-0 text-3xl border-[#CB445C] w-full rounded-none"
        />
        {/* <input
          type="number"
          className="input border-0 border-b px-0 text-3xl border-[#CB445C] w-full rounded-none"
        />
        <input
          type="number"
          className="input border-0 border-b px-0 text-3xl border-[#CB445C] w-full rounded-none"
        />
        <input
          type="number"
          className="input border-0 border-b px-0 text-3xl border-[#CB445C] w-full rounded-none"
        /> */}
      </div>
      <p className="mt-2">
        <span className="text-[#CB445C] font-medium">Lovetamin</span> will send
        you verification code. Message and data rates may apply.
      </p>

      {/* <Link to="/mobile-number" className="absolute bottom-10 right-8"> */}
      <Link to="/password" className="absolute bottom-10 right-8">
        <div className="h-14 w-14 rounded-full border border-[#CB445C] bg-[#CB445C] flex justify-center items-center">
          <IoIosArrowForward className="text-white text-2xl" />
        </div>
      </Link>
    </DefaultLayout>
  );
};

export default EmailOTP;
