import React, { useState } from "react";
import Footer from "../../Layouts/Footer";
import profile1 from "../../assets/profile1.jpeg";
import { friends } from "../../Components/Common/Data";
import { LuDot } from "react-icons/lu";
import { IoMdSearch } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import AllTab from "./AllTab";
import LiveTab from "./LiveTab";
import ProfileDrawer from "../../Components/Modal/ProfileDrawer";
const Chats = () => {
  const [activeTab, setActiveTab] = useState("all");
  const [visible, setVisible] = useState(false);
  const navigate = useNavigate();

  const handleChatClick = (id) => {
    navigate(`/chatdetails/${id}`);
  };
  return (
    <div className="">
      <div className="flex justify-between items-center py-6 px-6 pt-10">
        <div
          className="h-9 w-9 rounded-full bg-[#222222]"
          onClick={() => setVisible(true)}>
          <img
            src={profile1}
            alt=""
            className="h-full w-full object-fill rounded-full"
          />
        </div>
        <div className="flex justify-center items-center gap-5">
          <div
            className={`px-4 py-0.5 rounded-full text-[16px] cursor-pointer ${
              activeTab === "all" ? "bg-black text-white" : "text-black border"
            }`}
            onClick={() => setActiveTab("all")}>
            All
          </div>
          <div
            className={`px-4 py-0.5 rounded-full border text-black text-[16px] cursor-pointer ${
              activeTab === "nowLive" ? "bg-black text-white" : "bg-white"
            }`}
            onClick={() => setActiveTab("nowLive")}>
            Live Now
          </div>
        </div>
      </div>
      <div className="h-[800px] overflow-y-auto">
        {activeTab === "all" && <AllTab handleChatClick={handleChatClick} />}
        {activeTab === "nowLive" && (
          <LiveTab handleChatClick={handleChatClick} />
        )}
      </div>
      <div
        className="absolute bottom-0 left-0 w-[100%]"
        style={{
          borderTop: "2px solid #f5c5d8",
          background: "#fcf3fe",
          boxShadow: "-5px 5px -5px #dc354578 !important",
        }}>
        <Footer />
      </div>
      <ProfileDrawer visible={visible} setVisible={setVisible} />
    </div>
  );
};

export default Chats;
