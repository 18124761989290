import React from "react";
import DefaultLayout from "../Components/Common/DefaultLayout";
import { Link } from "react-router-dom";
import { IoIosArrowForward } from "react-icons/io";
import LogoNameAnimation from "../Components/Common/LogoNameAnimation";

const Done = () => {
  return (
    <DefaultLayout>
      <h2 className="font-semibold text-[38px] mt-20 leading-[50px]">
        All done! Let's see who catches your eye.
      </h2>
      <LogoNameAnimation />

      <Link
        to="/home"
        className="h-20 w-full font-medium py-3 border border-[#CB445C] bg-[#CB445C] flex justify-center items-center absolute bottom-0 left-0">
        <p className="text-white text-[19px]">Get Started</p>
        <IoIosArrowForward className="text-white text-2xl" />
      </Link>
    </DefaultLayout>
  );
};

export default Done;
