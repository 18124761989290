import React, { useState } from "react";
import { IoIosArrowForward } from "react-icons/io";
import { Link } from "react-router-dom";
import { LuGraduationCap } from "react-icons/lu";
import DefaultLayout from "../Components/Common/DefaultLayout";
import CommonHeader from "../Components/Common/CommonHeader";
import CommonRadio from "../Components/Common/CommonRadio";

const Education = () => {
  const [showMoreOptions, setShowMoreOptions] = useState(false);

  const handleViewMoreClick = () => {
    setShowMoreOptions((prev) => !prev);
  };

  return (
    <DefaultLayout>
      <div className="h-10 w-10 rounded-full border border-gray-600 flex justify-center items-center mt-10">
        <LuGraduationCap className="text-xl" />
      </div>
      
      <div className="flex justify-between items-start gap-1 w-[100%]">
        <CommonHeader title="What's your education level?" />
        <div className="skip">
          <Link to="/career" className="">
            Skip Now
          </Link>
        </div>
      </div>

      <div className="mt-8">
        <CommonRadio title="Primary education" />
        <CommonRadio title="Secondary education" />
        <CommonRadio title="Doctorate (PhD) degree" />
        <CommonRadio title="Bachelor's degree" />
        <CommonRadio title="Professional degree" />
        {showMoreOptions && (
          <>
            <CommonRadio title="Post-Secondary/High School" />
            <CommonRadio title="Associate's Degree" />
            <CommonRadio title="Master's Degree" />
          </>
        )}

        <div className="indicator w-[97%] mt-8" onClick={handleViewMoreClick}>
          <div className="text-gray-500 text-[14px] rounded-md grid h-10 border border-dashed w-full place-items-center cursor-pointer">
            {showMoreOptions ? "" : "View more options +"}
          </div>
        </div>
      </div>
      <div className="flex justify-normal items-center gap-1 mt-8">
        <input type="checkbox" className="checkbox" />
        <p>Visible on profile</p>
      </div>
      <div className="mt-14">
        <p>
          <span className="text-[#cb445c] font-semibold">Learn more </span>
          about education wise our recommended people.
        </p>
      </div>
      <Link to="/career" className="absolute bottom-10 right-8">
        <div className="h-14 w-14 rounded-full border border-[#CB445C] bg-[#CB445C] flex justify-center items-center">
          <IoIosArrowForward className="text-white text-2xl" />
        </div>
      </Link>
    </DefaultLayout>
  );
};

export default Education;
