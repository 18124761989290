import React, { useState } from "react";
import Footer from "../../Layouts/Footer";
import profile1 from "../../assets/profile1.jpeg";
import { TiUserAddOutline } from "react-icons/ti";
import { BiSolidMessageRoundedDetail } from "react-icons/bi";
import { TbDotsVertical } from "react-icons/tb";
import { peapleMayknow, friends } from "../../Components/Common/Data";
import SuggestionTab from "./SuggestionTab";
import FriendsTab from "./FriendsTab";
import ProfileDrawer from "../../Components/Modal/ProfileDrawer";

const Match = () => {
  const [activeTab, setActiveTab] = useState("suggestions");
  const [visible, setVisible] = useState(false);
  const truncateBio = (bio, charLimit) => {
    return bio.length > charLimit ? bio.slice(0, charLimit) + "..." : bio;
  };

  return (
    <div className="">
      <div className="flex justify-around items-center py-6 pt-10">
        <div
          className="h-9 w-9 rounded-full bg-[#222222]"
          onClick={() => setVisible(true)}>
          <img
            src={profile1}
            alt=""
            className="h-full w-full object-fill rounded-full"
          />
        </div>
        <div className="flex justify-center items-center gap-5">
          <div
            className={`px-4 py-0.5 rounded-full text-[16px] cursor-pointer ${
              activeTab === "suggestions"
                ? "bg-black text-white"
                : "text-black border"
            }`}
            onClick={() => setActiveTab("suggestions")}>
            Suggestions
          </div>
          <div
            className={`px-4 py-0.5 rounded-full border text-black text-[16px] cursor-pointer ${
              activeTab === "friends" ? "bg-black text-white" : "bg-white"
            }`}
            onClick={() => setActiveTab("friends")}>
            Your friends
          </div>
        </div>
      </div>
      <div className="h-[800px] overflow-y-auto">
        {activeTab === "suggestions" && (
          <SuggestionTab truncateBio={truncateBio} />
        )}
        {activeTab === "friends" && <FriendsTab />}
      </div>
      <div
        className="absolute bottom-0 left-0 w-[100%]"
        style={{
          borderTop: "2px solid #f5c5d8",
          background: "#fcf3fe",
          boxShadow: "-5px 5px -5px #dc354578 !important",
        }}>
        <Footer />
      </div>
      <ProfileDrawer visible={visible} setVisible={setVisible} />
    </div>
  );
};

export default Match;
