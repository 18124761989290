import React, { useState } from "react";
import { IoIosArrowForward } from "react-icons/io";
import { LiaBirthdayCakeSolid } from "react-icons/lia";
import { Link } from "react-router-dom";
import { Calendar } from "primereact/calendar";
import DefaultLayout from "../Components/Common/DefaultLayout";
import CommonHeader from "../Components/Common/CommonHeader";

const BirthDate = () => {
  const [date, setDate] = useState(null);
  const [age, setAge] = useState(null);

  const calculateAge = (birthDate) => {
    const today = new Date();
    const birthDateObj = new Date(birthDate);
    let age = today.getFullYear() - birthDateObj.getFullYear();
    const monthDifference = today.getMonth() - birthDateObj.getMonth();

    if (
      monthDifference < 0 ||
      (monthDifference === 0 && today.getDate() < birthDateObj.getDate())
    ) {
      age--;
    }

    return age;
  };

  const handleDateChange = (e) => {
    console.log("e", e);
    const selectedDate = e.value;
    setDate(selectedDate);
    setAge(calculateAge(selectedDate));
  };
  return (
    <DefaultLayout>
      <div className="h-10 w-10 rounded-full border border-gray-600 flex justify-center items-center mt-10">
        <LiaBirthdayCakeSolid className="text-xl" />
      </div>
      
      <div className="flex justify-between items-start gap-1 w-[100%]">
        <CommonHeader title={"What's your date of birth?"} />
        <div className="skip">
          <Link to="/location" className="">
            Skip Now
          </Link>
        </div>
      </div>

      <div className="card flex justify-content-center mt-10 bg-[#f5dde12b] border border-[#e99ca92b] p-2 rounded-[10px]">
        <Calendar value={date} onChange={(e) => handleDateChange(e)} inline />
      </div>
      <p className="text-center py-4">
        Age:{" "}
        <span className="text-[18px] font-semibold">
          {age !== null ? age : "Select your date of birth"}
        </span>
      </p>
      <p className="text-center text-[#bf939c]">This can't be changed later</p>

      <Link to="/location" className="absolute bottom-10 right-8">
        <div className="h-14 w-14 rounded-full border border-[#CB445C] bg-[#CB445C] flex justify-center items-center">
          <IoIosArrowForward className="text-white text-2xl" />
        </div>
      </Link>
    </DefaultLayout>
  );
};

export default BirthDate;
