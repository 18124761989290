import profile_image from "../../assets/profile-img1.jpeg";
import profile_image1 from "../../assets/profile-img2.jpeg";
import profile_image2 from "../../assets/profile-img3.jpeg";
import profile_image3 from "../../assets/profile-img4.jpeg";
import profile_image4 from "../../assets/profile-img5.jpeg";

export const peapleMayknow = [
  {
    id: 1,
    image: profile_image,
    name: "Jane Smith",
    age: 28,
    location: "Los Angeles",
    bio: "Avid hiker and coffee enthusiast. Always up for an adventure.",
    status: "active",
  },
  {
    id: 2,
    image: profile_image1,
    name: "Michael Johnson",
    age: 32,
    location: "Chicago",
    bio: "Tech geek and dog lover. Enjoy coding and outdoor activities.",
  },

  {
    id: 3,
    image: profile_image2,
    name: "Emily Davis",
    age: 24,
    location: "Austin",
    bio: "Passionate about painting and music. Love to explore new places.",
    status: "active",
  },
  {
    id: 4,
    image: profile_image3,
    name: "Chris Brown",
    age: 29,
    location: "San Francisco",
    bio: "Foodie and traveler. Always looking for the next great meal.",
  },
  {
    id: 5,
    image: profile_image4,
    name: "Amanda Wilson",
    age: 27,
    location: "Seattle",
    bio: "Fitness fanatic and bookworm. Love spending time with my cat.",
  },
];
export const friends = [
  {
    id: 3,
    image: profile_image2,
    name: "Emily Davis",
    age: 24,
    location: "Austin",
    bio: "Passionate about painting and music. Love to explore new places.",
    status: "active",
    live: "yes",
  },
  {
    id: 2,
    image: profile_image1,
    name: "Michael Johnson",
    age: 32,
    location: "Chicago",
    bio: "Tech geek and dog lover. Enjoy coding and outdoor activities.",
  },
  {
    id: 4,
    image: profile_image3,
    name: "Chris Brown",
    age: 29,
    location: "San Francisco",
    bio: "Foodie and traveler. Always looking for the next great meal.",
    status: "active",
    live: "yes",
  },
  {
    id: 1,
    image: profile_image,
    name: "Jane Smith",
    age: 28,
    location: "Los Angeles",
    bio: "Avid hiker and coffee enthusiast. Always up for an adventure.",
  },
  {
    id: 5,
    image: profile_image4,
    name: "Amanda Wilson",
    age: 27,
    location: "Seattle",
    bio: "Fitness fanatic and bookworm. Love spending time with my cat.",
  },
  {
    id: 6,
    image: profile_image1,
    name: "Michael Johnson",
    age: 32,
    location: "Chicago",
    bio: "Tech geek and dog lover. Enjoy coding and outdoor activities.",
    status: "active",
  },
  {
    id: 7,
    image: profile_image3,
    name: "Chris Brown",
    age: 29,
    location: "San Francisco",
    bio: "Foodie and traveler. Always looking for the next great meal.",
    status: "active",
  },
  {
    id: 8,
    image: profile_image,
    name: "Jane Smith",
    age: 28,
    location: "Los Angeles",
    bio: "Avid hiker and coffee enthusiast. Always up for an adventure.",
    status: "active",
  },

  {
    id: 9,
    image: profile_image2,
    name: "Emily Davis",
    age: 24,
    location: "Austin",
    bio: "Passionate about painting and music. Love to explore new places.",
    status: "active",
  },

  {
    id: 10,
    image: profile_image4,
    name: "Amanda Wilson",
    age: 27,
    location: "Seattle",
    bio: "Fitness fanatic and bookworm. Love spending time with my cat.",
  },
  {
    id: 11,
    image: profile_image1,
    name: "Michael Johnson",
    age: 32,
    location: "Chicago",
    bio: "Tech geek and dog lover. Enjoy coding and outdoor activities.",
  },
  {
    id: 12,
    image: profile_image3,
    name: "Chris Brown",
    age: 29,
    location: "San Francisco",
    bio: "Foodie and traveler. Always looking for the next great meal.",
    status: "active",
  },
  {
    id: 13,
    image: profile_image,
    name: "Jane Smith",
    age: 28,
    location: "Los Angeles",
    bio: "Avid hiker and coffee enthusiast. Always up for an adventure.",
    status: "active",
  },

  {
    id: 14,
    image: profile_image2,
    name: "Emily Davis",
    age: 24,
    location: "Austin",
    bio: "Passionate about painting and music. Love to explore new places.",
  },

  {
    id: 15,
    image: profile_image4,
    name: "Amanda Wilson",
    age: 27,
    location: "Seattle",
    bio: "Fitness fanatic and bookworm. Love spending time with my cat.",
  },
  {
    id: 16,
    image: profile_image1,
    name: "Michael Johnson",
    age: 32,
    location: "Chicago",
    bio: "Tech geek and dog lover. Enjoy coding and outdoor activities.",
  },
  {
    id: 17,
    image: profile_image3,
    name: "Chris Brown",
    age: 29,
    location: "San Francisco",
    bio: "Foodie and traveler. Always looking for the next great meal.",
  },
  {
    id: 18,
    image: profile_image,
    name: "Jane Smith",
    age: 28,
    location: "Los Angeles",
    bio: "Avid hiker and coffee enthusiast. Always up for an adventure.",
  },

  {
    id: 19,
    image: profile_image2,
    name: "Emily Davis",
    age: 24,
    location: "Austin",
    bio: "Passionate about painting and music. Love to explore new places.",
  },

  {
    id: 20,
    image: profile_image4,
    name: "Amanda Wilson",
    age: 27,
    location: "Seattle",
    bio: "Fitness fanatic and bookworm. Love spending time with my cat.",
  },
  {
    id: 21,
    image: profile_image1,
    name: "Michael Johnson",
    age: 32,
    location: "Chicago",
    bio: "Tech geek and dog lover. Enjoy coding and outdoor activities.",
  },
  {
    id: 22,
    image: profile_image3,
    name: "Chris Brown",
    age: 29,
    location: "San Francisco",
    bio: "Foodie and traveler. Always looking for the next great meal.",
  },
  {
    id: 23,
    image: profile_image,
    name: "Jane Smith",
    age: 28,
    location: "Los Angeles",
    bio: "Avid hiker and coffee enthusiast. Always up for an adventure.",
  },

  {
    id: 24,
    image: profile_image2,
    name: "Emily Davis",
    age: 24,
    location: "Austin",
    bio: "Passionate about painting and music. Love to explore new places.",
  },

  {
    id: 25,
    image: profile_image4,
    name: "Amanda Wilson",
    age: 27,
    location: "Seattle",
    bio: "Fitness fanatic and bookworm. Love spending time with my cat.",
  },
];
