import React from "react";

const CommonRadio = ({ title }) => {
  return (
    <div className="form-control py-1">
      <label className="label cursor-pointer border-0 border-b">
        <span className="label-text text-[17px]">{title}</span>
        <input
          type="radio"
          name="radio-10"
          className="custom-radio radio radio-secondary border border-[#CB445C]"
        />
      </label>
    </div>
  );
};

export default CommonRadio;
