import React, { useState, useEffect } from "react";
import loginBackground from "../assets/loginIntro.jpeg";
import { Link } from "react-router-dom";
import logo from "../assets/logos.png";
import Loader from "../Components/Common/Loader";

const GettingStartPage = () => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Simulate a loading delay
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 2000);

    return () => clearTimeout(timer);
  }, []);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div
      className="px-5"
      style={{
        backgroundImage: `url(${loginBackground})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        height: "100vh",
      }}>
      <div className="pt-[120px] pb-[290px]">
        <img src={logo} alt="" width={50} className="mx-auto" />
        <h2 className="text-center text-[#cb445c] text-[40px] font-semibold">
          Lovetamin
        </h2>
        <p className="text-center font-medium text-gray-200 text-[16px]">
          Designed to be deleted
        </p>
      </div>
      <div className="text-white">
        <p className="text-center px-2 text-[14px]">
          By Tapping 'Sign in' / 'Create Account'. You Agree To Our
          <a className="underline px-2 font-medium hover:text-blue-400 text-[14px]">
            Term of Service.
          </a>
          Learn How We Process Your Data in Our
          <a className="underline px-2 font-medium hover:text-blue-400 text-[14px]">
            Privacy Policy
          </a>{" "}
          and
          <a className="underline px-2 font-medium hover:text-blue-400 text-[14px]">
            Cookies Policy.
          </a>
        </p>
        <Link to="/email">
          <button className="text-white font-semibold rounded-full w-full py-2.5 bg-[#CB445C] mt-5">
            Create Account
          </button>
        </Link>

        <p className="text-center mt-7">
          <Link to="/login-start" className="font-semibold">
            Sign In
          </Link>
        </p>
      </div>
    </div>
  );
};

export default GettingStartPage;
