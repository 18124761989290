import React from "react";
import { friends } from "../../Components/Common/Data";
import { BiSolidMessageRoundedDetail } from "react-icons/bi";
import { TbDotsVertical } from "react-icons/tb";
import { useNavigate } from "react-router-dom";

const FriendsTab = () => {
  const navigate = useNavigate();

  const handleChatClick = (id) => {
    navigate(`/chatdetails/${id}`);
  };
  return (
    <div className="card mx-6 mt-6 pb-10">
      <p className="text-2xl font-semibold">228 friends</p>
      <div className="mt-4">
        {friends?.map((e, i) => (
          <div className="rounded-[5px] mt-4 shadow border p-4" key={i}>
            <div className="flex justify-between items-center">
              <div className="flex justify-normal items-center gap-2">
                <div className="h-[50px] w-[50px] rounded-full">
                  <img
                    src={e?.image}
                    alt=""
                    className="w-full h-full object-cover rounded-full"
                  />
                </div>
                <div>
                  <p className="text-[18px] font-medium">{e?.name}</p>
                  <p className="text-[16px] font-normal text-gray-500">
                    22 mutual friends
                  </p>
                </div>
              </div>
              <div className="flex justify-normal gap-3 items-center">
                <div
                  className="border border-[#e4b0ba] bg-white text-gray-600 h-11 w-11 rounded-full flex justify-center items-center"
                  onClick={() => handleChatClick(e.id)}>
                  <BiSolidMessageRoundedDetail className="text-xl text-[#CB445C]" />
                </div>
                <TbDotsVertical className="text-xl font-semibold" />
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default FriendsTab;
