import React from "react";
import { IoIosArrowForward } from "react-icons/io";
import { Link } from "react-router-dom";
import DefaultLayout from "../Components/Common/DefaultLayout";
import LogoNameAnimation from "../Components/Common/LogoNameAnimation";

const BasicInfo = () => {
  return (
    <DefaultLayout>
      <h2 className="font-semibold text-[38px] mt-20 leading-[50px]">
        You're one of a kind. Your profile should be, too.
      </h2>
      <LogoNameAnimation />

      <Link
        to="/name"
        className="h-20 w-full font-medium py-3 border border-[#CB445C] bg-[#CB445C] flex justify-center items-center absolute bottom-0 left-0">
        <p className="text-white text-[19px]">Enter your basic info</p>
        <IoIosArrowForward className="text-white text-2xl" />
      </Link>
    </DefaultLayout>
  );
};

export default BasicInfo;
