import React, { useState } from 'react';
import { IoIosArrowForward } from "react-icons/io";
import { Link } from "react-router-dom";
import { RiLockPasswordLine } from "react-icons/ri";
import DefaultLayout from "../Components/Common/DefaultLayout";
import CommonHeader from "../Components/Common/CommonHeader";
import axios from 'axios';
import { FaEye, FaEyeSlash } from 'react-icons/fa';


const Password = () => {
  const [password, setPassword] = useState('');
  const [repassword, setRepassword] = useState('');
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showRepassword, setShowRepassword] = useState(false);

  const validatePasswords = () => {
    if (password.length < 8) {
      setError('Password must be at least 8 characters long.');
      return false;
    }

    if (password !== repassword) {
      setError('Passwords do not match.');
      return false;
    }

    setError(null);
    return true;
  };

  const togglePasswordVisibility = () => {
    setShowPassword(prevState => !prevState);
  };
  const toggleRepasswordVisibility = () => {
    setShowRepassword(prevState => !prevState);
  };


  const handleSubmit = () => {
    if (validatePasswords()) {
      const validatePasswordUrl = process.env.REACT_APP_OTP_SEND_URL + '/accounts/{id}';
      const payload = { password: password };
      axios.post(validatePasswordUrl, payload)
        .then(response => {
          if (response.data.success) {
            setSuccess(true);
            setError(null);
          } else {
            setError('Failed to update password.');
          }
        })
        .catch(error => {
          console.error('API Error:', error.response ? error.response.data : error.message);
          setError('Error updating password.');
        });
    }
  };

  return (
    <DefaultLayout>
      <div className="h-10 w-10 rounded-full border border-gray-600 flex justify-center items-center mt-10">
        <RiLockPasswordLine className="text-xl" />
      </div>

      <div className="flex justify-between items-start gap-1 w-[100%]">
        <CommonHeader title="Enter your Password?" />
        <div className="skip">
          <Link to="/basic-info" className="">
            Skip Now
          </Link>
        </div>
      </div>

      <div className="password-form">
        <div className="form-group">
          <div className="relative w-full">
            <input
              type={showPassword ? "text" : "password"}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="input rounded-none border-0 border-b border-[#CB445C] w-full px-0"
              placeholder="Enter new password"
            />
            <div
              className="absolute inset-y-0 right-0 flex items-center pr-3 cursor-pointer"
              onClick={togglePasswordVisibility}
            >
              {showPassword ? <FaEyeSlash /> : <FaEye />}
            </div>
          </div>
        </div>

        <div className="form-group">
          <h2 className="font-semibold text-[30px] mt-10 text-nowrap">
            Re-Enter Password
          </h2>
          <div className="relative w-full">
            <input
              type={showRepassword ? "text" : "password"}
              value={repassword}
              onChange={(e) => setRepassword(e.target.value)}
              className="input rounded-none border-0 border-b border-[#CB445C] w-full mt-5 px-0"
              placeholder="Re-type your password"
            />
            <div
              className="absolute inset-y-0 right-0 flex items-center pr-3 cursor-pointer"
              onClick={toggleRepasswordVisibility}
            >
              {showRepassword ? <FaEyeSlash className="icon" /> : <FaEye className="icon" />}
            </div>
          </div>
        </div>


        {error && <p className="text-red-500">{error}</p>}
        {success && <p className="text-green-500">Password updated successfully!</p>}

        <button onClick={handleSubmit} className="absolute bottom-10 right-8"to="/basic-info">
          <div className="h-14 w-14 rounded-full border border-[#CB445C] bg-[#CB445C] flex justify-center items-center">
            <IoIosArrowForward className="text-white text-2xl" />
          </div>
        </button>
        
      </div>

    </DefaultLayout>
  );
};

export default Password;
