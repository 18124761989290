import React from "react";
import logo from "../../assets/logos.png";
const LogoNameAnimation = () => {
  return (
    <div className="h-[400px] overflow-y-auto py-32">
      <div
        className="wavy flex justify-center items-end"
        style={{ letterSpacing: "14px" }}>
        <span style={{ "--i": 1 }} className="mt-5">
          <img src={logo} alt="" width={40} />
        </span>
        <span style={{ "--i": 2 }}>L </span>
        <span
          style={{
            "--i": 3,
          }}>
          o
        </span>
        <span
          style={{
            "--i": 4,
          }}>
          v
        </span>
        <span
          style={{
            "--i": 5,
          }}>
          e
        </span>
        <span
          style={{
            "--i": 6,
          }}>
          t
        </span>
        <span
          style={{
            "--i": 7,
          }}>
          a
        </span>
        <span
          style={{
            "--i": 8,
          }}>
          m
        </span>
        <span
          style={{
            "--i": 9,
          }}>
          i
        </span>
        <span
          style={{
            "--i": 10,
          }}>
          n
        </span>
      </div>
    </div>
  );
};

export default LogoNameAnimation;
