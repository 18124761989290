import React, { useState } from "react";
import { IoIosArrowForward } from "react-icons/io";
import { Link } from "react-router-dom";
import DefaultLayout from "../Components/Common/DefaultLayout";
import { IoIosMagnet } from "react-icons/io";
import CommonRadio from "../Components/Common/CommonRadio";
import { FaEyeSlash } from "react-icons/fa";
import { FaEye } from "react-icons/fa";
import CommonHeader from "../Components/Common/CommonHeader";

const Sexuality = () => {
  const [isVisible, setIsVisible] = useState(false);

  const toggleVisibility = () => {
    setIsVisible((prev) => !prev);
  };

  return (
    <DefaultLayout>
      <div className="h-10 w-10 rounded-full border border-gray-600 flex justify-center items-center mt-10">
        <IoIosMagnet className="text-xl rotate-90" />
      </div>
      
      <div className="flex justify-between items-start gap-1 w-[100%]">
        <CommonHeader title="What's your sexuality?" />
        <div className="skip">
          <Link to="/relationship" className="">
            Skip Now
          </Link>
        </div>
      </div>

      <div className="mt-8">
        <CommonRadio title="Prefer not to say" />
        <CommonRadio title="Straight" />
        <CommonRadio title="Gay" />
        <CommonRadio title="Lasbian" />
        <CommonRadio title="Bisexual" />
        <CommonRadio title="Allosexual" />
        <CommonRadio title="Androsexual" />
      </div>

      <div className="flex justify-normal items-center gap-1 mt-14">
        {isVisible ? (
          <FaEye
            onClick={toggleVisibility}
            className="cursor-pointer text-xl"
          />
        ) : (
          <FaEyeSlash
            onClick={toggleVisibility}
            className="cursor-pointer text-xl"
          />
        )}
        <p>{isVisible ? "Visible on profile" : "Hidden on profile"}</p>
      </div>

      <div className="mt-24">
        <p className="text-[#cb445c] font-semibold hover:underline cursor-pointer">
          Feedback on sexuality?
        </p>
      </div>
      <Link to="/relationship" className="absolute bottom-10 right-8">
        <div className="h-14 w-14 rounded-full border border-[#CB445C] bg-[#CB445C] flex justify-center items-center">
          <IoIosArrowForward className="text-white text-2xl" />
        </div>
      </Link>
    </DefaultLayout>
  );
};

export default Sexuality;
