import { FaRegComments } from "react-icons/fa6";
import React from "react";
import DefaultLayout from "../Components/Common/DefaultLayout";
import CommonHeader from "../Components/Common/CommonHeader";
import { IoIosArrowForward } from "react-icons/io";
import { Link } from "react-router-dom";
import { FaRegHeart } from "react-icons/fa";
import CommonRadio from "../Components/Common/CommonRadio";

const LoveLanguage = () => {
  return (
    <DefaultLayout>
      <div className="h-10 w-10 rounded-full border border-gray-600 flex justify-center items-center mt-10">
        <FaRegHeart className="text-xl" />
      </div>
      
      <div className="flex justify-between items-start gap-1 w-[100%]">
        <CommonHeader title={"What's your love language?"} />
        <div className="skip">
          <Link to="/personality" className="">
            Skip Now
          </Link>
        </div>
      </div>

      <div className="mt-8">
        <CommonRadio title="Gifts" />
        <CommonRadio title="Words of affirmation" />
        <CommonRadio title="Acts of service" />
        <CommonRadio title="Quality Time" />
      </div>

      <Link to="/personality" className="absolute bottom-10 right-8">
        <div className="h-14 w-14 rounded-full border border-[#CB445C] bg-[#CB445C] flex justify-center items-center">
          <IoIosArrowForward className="text-white text-2xl" />
        </div>
      </Link>
    </DefaultLayout>
  );
};

export default LoveLanguage;
