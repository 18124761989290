// import React from "react";
// import loginBackground from "../../assets/loginImage.png";
// import { Link } from "react-router-dom";
// import logo from "../../assets/logos.png";
// import { FaFacebook } from "react-icons/fa6";
// import { FaGoogle } from "react-icons/fa";
// import { IoIosArrowBack } from "react-icons/io";
// const LoginStart = () => {
//   return (
//     <div
//       className="px-5"
//       style={{
//         backgroundImage: `url(${loginBackground})`,
//         backgroundSize: "cover",
//         backgroundPosition: "center",
//         height: "100vh",
//       }}>
//       <div className="pt-[110px] pb-[30px]">
//         <img src={logo} alt="" width={50} className="mx-auto" />
//         <h2 className="text-center text-[#cb445c] text-[40px] font-semibold">
//           Lovetamin
//         </h2>
//         <p className="text-center font-medium text-gray-200 text-[16px]">
//           Designed to be deleted
//         </p>
//       </div>

//       <div className="mt-8 mb-4">
//         <div className="form-control">
//           <input
//             type="text"
//             placeholder="Username"
//             className="input bg-transparent rounded-full border border-[#CB445C] w-full px-3 text-white"
//           />
//         </div>
//         <div className="form-control mt-5">
//           <input
//             type="password"
//             placeholder="password"
//             className="input bg-transparent rounded-full border border-[#CB445C] w-full px-3 text-white"
//           />
//         </div>
//         <Link to="/email">
//           <button className="flex justify-center items-center gap-2 text-white font-semibold rounded-full w-full py-3 bg-[#CB445C] mt-5">
//             Login
//           </button>
//         </Link>
//       </div>
//       <div className="divider divider-neutral mt-8 text-white">OR</div>
//       <div className="text-white">
//         <Link to="/email">
//           <button className="flex justify-center items-center gap-2 text-white font-semibold rounded-full w-full py-3 bg-[#447ecb] mt-5">
//             Login with Facebook
//             <FaFacebook />
//           </button>
//         </Link>
//         <Link to="/email">
//           <button className="flex justify-center items-center gap-2 text-white font-semibold rounded-full w-full py-3 bg-red-400 mt-5">
//             Login with Google
//             <FaGoogle />
//           </button>
//         </Link>

//         <p className="text-center mt-7">
//           <Link
//             to="/"
//             className="font-semibold flex justify-center items-center gap-3">
//             <IoIosArrowBack /> back
//           </Link>
//         </p>
//       </div>
//     </div>
//   );
// };

// export default LoginStart;

import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import loginBackground from "../../assets/loginImage.png";
import { Link } from "react-router-dom";
import logo from "../../assets/logos.png";
import { FaFacebook, FaGoogle } from "react-icons/fa";
import { IoIosArrowBack } from "react-icons/io";

const LoginStart = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const handleLogin = () => {
    if (username === "admin@admin.com" && password === "admin1") {
      navigate("/home");
    } else {
      setError("Please type correct credentials");
    }
  };

  return (
    <div
      className="px-5"
      style={{
        backgroundImage: `url(${loginBackground})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        height: "100vh",
      }}>
      <div className="pt-[110px] pb-[30px]">
        <img src={logo} alt="" width={50} className="mx-auto" />
        <h2 className="text-center text-[#cb445c] text-[40px] font-semibold">
          Lovetamin
        </h2>
        <p className="text-center font-medium text-gray-200 text-[16px]">
          Designed to be deleted
        </p>
      </div>

      <div className="mt-8 mb-4">
        <div className="form-control">
          <input
            type="text"
            placeholder="Username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            className="input bg-transparent rounded-full border border-[#CB445C] w-full px-3 text-white"
          />
        </div>
        <div className="form-control mt-5">
          <input
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className="input bg-transparent rounded-full border border-[#CB445C] w-full px-3 text-white"
          />
        </div>
        <button
          onClick={handleLogin}
          className="flex justify-center items-center gap-2 text-white font-semibold rounded-full w-full py-3 bg-[#CB445C] mt-5">
          Login
        </button>
        {error && <p className="text-red-500 text-center mt-3">{error}</p>}
      </div>
      <div className="divider divider-neutral mt-8 text-white">OR</div>
      <div className="text-white">
        <Link to="/email">
          <button className="flex justify-center items-center gap-2 text-white font-semibold rounded-full w-full py-3 bg-[#447ecb] mt-5">
            Login with Facebook
            <FaFacebook />
          </button>
        </Link>
        <Link to="/email">
          <button className="flex justify-center items-center gap-2 text-white font-semibold rounded-full w-full py-3 bg-red-400 mt-5">
            Login with Google
            <FaGoogle />
          </button>
        </Link>

        <p className="text-center mt-7">
          <Link
            to="/"
            className="font-semibold flex justify-center items-center gap-3">
            <IoIosArrowBack /> Back
          </Link>
        </p>
      </div>
    </div>
  );
};

export default LoginStart;
