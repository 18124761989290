// Loader.js
import React from "react";

const Loader = () => {
  return (
    <div className="flex items-center justify-center h-screen">
      {/* <h2 className="text-center text-2xl font-semibold">Loading...</h2> */}

      <div
        className="wavy flex justify-center items-end"
        style={{ letterSpacing: "14px" }}>
        {/* <span style={{ "--i": 1 }} className="mt-5">
          <img src={logo} alt="" width={40} />
        </span> */}
        <span style={{ "--i": 2 }}>L </span>
        <span
          style={{
            "--i": 3,
          }}>
          o
        </span>
        <span
          style={{
            "--i": 4,
          }}>
          a
        </span>
        <span
          style={{
            "--i": 5,
          }}>
          d
        </span>
        <span
          style={{
            "--i": 6,
          }}>
          i
        </span>
        <span
          style={{
            "--i": 7,
          }}>
          n
        </span>
        <span
          style={{
            "--i": 8,
          }}>
          g
        </span>
        <span
          style={{
            "--i": 9,
          }}>
          {" "}
          .
        </span>
        <span
          style={{
            "--i": 10,
          }}>
          .
        </span>
        <span
          style={{
            "--i": 11,
          }}>
          .
        </span>
      </div>
    </div>
  );
};

export default Loader;
