import React from "react";
import { LuDot } from "react-icons/lu";
import { friends } from "../../Components/Common/Data";
import { IoMdSearch } from "react-icons/io";
import { RiLiveLine } from "react-icons/ri";

const LiveTab = ({ handleChatClick }) => {
  const liveFriends = friends?.filter((e) => e?.live == "yes");
  return (
    <div className="card mx-6 mt-6 pb-10">
      <div className="">
        <p className="text-2xl font-semibold mb-2">Chats</p>
        <div className="flex justify-normal items-center border border-[#CB445C] rounded-full">
          <div className="h-11 w-12 pl-2 flex justify-center items-center rounded-l-full bg-white">
            <IoMdSearch className="text-2xl text-[#c4c3c3]" />
          </div>
          <input
            type="text"
            placeholder="Search"
            className="input rounded-r-full border-l-0 w-full px-2 h-11"
          />
        </div>
      </div>
      <div className="mt-4">
        {liveFriends?.map((e, i) => (
          <div
            className="relative hover:bg-[#ece0e252] py-4 px-1"
            key={i}
            onClick={() => handleChatClick(e.id)}>
            <div className="flex justify-normal items-center gap-2">
              <div className="h-[50px] w-[50px] rounded-full relative">
                {e?.status == "active" && (
                  <div className="h-4 w-4 rounded-full bg-green-500 absolute top-[-3px] left-0"></div>
                )}

                <img
                  src={e?.image}
                  alt=""
                  className="w-full h-full object-cover rounded-full"
                />
              </div>
              <div>
                <p className="text-[18px] font-medium">{e?.name}</p>
                <div className="flex justify-normal items-center">
                  <p className="text-[16px] font-normal text-gray-500">
                    hello, how are you?
                  </p>
                  <LuDot />
                  <p className="text-[16px] font-normal text-gray-500">
                    9:15 PM
                  </p>
                </div>
              </div>{" "}
              <div
                className="border border-[#e4b0ba] bg-white text-gray-600 h-11 w-11 rounded-full flex justify-center items-center ml-5"
                onClick={() => handleChatClick(e.id)}>
                <RiLiveLine className="text-xl text-[#CB445C]" />
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default LiveTab;
