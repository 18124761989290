// import React from "react";
// import DefaultLayout from "../Components/Common/DefaultLayout";
// import { IoIosArrowForward } from "react-icons/io";
// import { Link } from "react-router-dom";
// import { BiImageAdd } from "react-icons/bi";
// import { MdOutlinePhotoSizeSelectActual } from "react-icons/md";
// import { PiLightbulbFilament } from "react-icons/pi";
// import CommonHeader from "../Components/Common/CommonHeader";
// const UploadVideoPhotos = () => {
//   return (
//     <DefaultLayout>
//       <div className="h-10 w-10 rounded-full border border-gray-600 flex justify-center items-center mt-10">
//         <MdOutlinePhotoSizeSelectActual className="text-xl" />
//       </div>
//       <CommonHeader title="Pick your videos and photos" />

//       <div className="mt-8 grid grid-cols-3 justify-normal items-center gap-4">
//         <div className="py-8 px-3 flex justify-center items-center rounded-md border border-dashed border-gray-500">
//           <BiImageAdd className="text-[30px] text-gray-500" />
//         </div>
//         <div className="py-8 px-3 flex justify-center items-center rounded-md border border-dashed border-gray-500">
//           <BiImageAdd className="text-[30px] text-gray-500" />
//         </div>
//         <div className="py-8 px-3 flex justify-center items-center rounded-md border border-dashed border-gray-500">
//           <BiImageAdd className="text-[30px] text-gray-500" />
//         </div>
//         <div className="py-8 px-3 flex justify-center items-center rounded-md border border-dashed border-gray-500">
//           <BiImageAdd className="text-[30px] text-gray-500" />
//         </div>
//         <div className="py-8 px-3 flex justify-center items-center rounded-md border border-dashed border-gray-500">
//           <BiImageAdd className="text-[30px] text-gray-500" />
//         </div>
//         <div className="py-8 px-3 flex justify-center items-center rounded-md border border-dashed border-gray-500">
//           <BiImageAdd className="text-[30px] text-gray-500" />
//         </div>
//       </div>

//       <div className="mt-14 relative">
//         <PiLightbulbFilament className="absolute top-[-20px] left-[45%] text-[30px]" />
//         <div className="border border-gray-400 w-full py-5 px-4 text-[14px] rounded-[10px] flex justify-center items-center text-center">
//           Video bring your profile to life, giving others a better sense of who
//           you are.
//         </div>
//       </div>

//       <Link to="/done" className="absolute bottom-10 right-8">
//         <div className="h-14 w-14 rounded-full border border-[#CB445C] bg-[#CB445C] flex justify-center items-center">
//           <IoIosArrowForward className="text-white text-2xl" />
//         </div>
//       </Link>
//     </DefaultLayout>
//   );
// };

// export default UploadVideoPhotos;

// import React, { useRef } from "react";
// import DefaultLayout from "../Components/Common/DefaultLayout";
// import { IoIosArrowForward } from "react-icons/io";
// import { Link } from "react-router-dom";
// import { BiImageAdd } from "react-icons/bi";
// import { MdOutlinePhotoSizeSelectActual } from "react-icons/md";
// import { PiLightbulbFilament } from "react-icons/pi";
// import CommonHeader from "../Components/Common/CommonHeader";

// const UploadVideoPhotos = () => {
//   const fileInputRefs = useRef([]);

//   const handleDivClick = (index) => {
//     fileInputRefs.current[index].click();
//   };

//   const handleFileChange = (event, index) => {
//     const file = event.target.files[0];
//     if (file) {
//       // Handle the selected file (e.g., upload it or display it)
//       console.log(`File selected for div ${index}:`, file);
//     }
//   };

//   return (
//     <DefaultLayout>
//       <div className="h-10 w-10 rounded-full border border-gray-600 flex justify-center items-center mt-10">
//         <MdOutlinePhotoSizeSelectActual className="text-xl" />
//       </div>
//       <CommonHeader title="Pick your videos and photos" />

//       <div className="mt-8 grid grid-cols-3 justify-normal items-center gap-4">
//         {Array.from({ length: 6 }).map((_, index) => (
//           <div
//             key={index}
//             className="py-8 px-3 flex justify-center items-center rounded-md border border-dashed border-gray-500"
//             onClick={() => handleDivClick(index)}>
//             <BiImageAdd className="text-[30px] text-gray-500" />
//             <input
//               type="file"
//               accept="image/*"
//               style={{ display: "none" }}
//               ref={(el) => (fileInputRefs.current[index] = el)}
//               onChange={(event) => handleFileChange(event, index)}
//             />
//           </div>
//         ))}
//       </div>

//       <div className="mt-14 relative">
//         <PiLightbulbFilament className="absolute top-[-20px] left-[45%] text-[30px]" />
//         <div className="border border-gray-400 w-full py-5 px-4 text-[14px] rounded-[10px] flex justify-center items-center text-center">
//           Video bring your profile to life, giving others a better sense of who
//           you are.
//         </div>
//       </div>

//       <Link to="/done" className="absolute bottom-10 right-8">
//         <div className="h-14 w-14 rounded-full border border-[#CB445C] bg-[#CB445C] flex justify-center items-center">
//           <IoIosArrowForward className="text-white text-2xl" />
//         </div>
//       </Link>
//     </DefaultLayout>
//   );
// };

// export default UploadVideoPhotos;

// import React, { useState, useRef } from "react";
// import DefaultLayout from "../Components/Common/DefaultLayout";
// import { IoIosArrowForward } from "react-icons/io";
// import { Link } from "react-router-dom";
// import { BiImageAdd } from "react-icons/bi";
// import { MdOutlinePhotoSizeSelectActual } from "react-icons/md";
// import { PiLightbulbFilament } from "react-icons/pi";
// import CommonHeader from "../Components/Common/CommonHeader";

// const UploadVideoPhotos = () => {
//   const [uploadedImages, setUploadedImages] = useState(Array(6).fill(null));
//   const fileInputRefs = useRef([]);

//   const handleDivClick = (index) => {
//     fileInputRefs.current[index].click();
//   };

//   const handleFileChange = (event, index) => {
//     const file = event.target.files[0];
//     if (file) {
//       const reader = new FileReader();
//       reader.onloadend = () => {
//         const updatedImages = [...uploadedImages];
//         updatedImages[index] = reader.result;
//         setUploadedImages(updatedImages);
//       };
//       reader.readAsDataURL(file);
//     }
//   };

//   return (
//     <DefaultLayout>
//       <div className="h-10 w-10 rounded-full border border-gray-600 flex justify-center items-center mt-10">
//         <MdOutlinePhotoSizeSelectActual className="text-xl" />
//       </div>
//       <CommonHeader title="Pick your videos and photos" />

//       <div className="mt-8 grid grid-cols-3 justify-normal items-center gap-4">
//         {uploadedImages.map((image, index) => (
//           <div
//             key={index}
//             className="h-[93px] flex justify-center items-center rounded-md border border-dashed border-gray-500"
//             onClick={() => handleDivClick(index)}
//             style={{ cursor: "pointer" }}>
//             {image ? (
//               <img
//                 src={image}
//                 alt={`Uploaded ${index}`}
//                 className="h-full w-full object-fill rounded-md"
//               />
//             ) : (
//               <BiImageAdd className="text-[30px] text-gray-500" />
//             )}
//             <input
//               type="file"
//               accept="image/*"
//               style={{ display: "none" }}
//               ref={(el) => (fileInputRefs.current[index] = el)}
//               onChange={(event) => handleFileChange(event, index)}
//             />
//           </div>
//         ))}
//       </div>

//       <div className="mt-14 relative">
//         <PiLightbulbFilament className="absolute top-[-20px] left-[45%] text-[30px]" />
//         <div className="border border-gray-400 w-full py-5 px-4 text-[14px] rounded-[10px] flex justify-center items-center text-center">
//           Video bring your profile to life, giving others a better sense of who
//           you are.
//         </div>
//       </div>

//       <Link to="/done" className="absolute bottom-10 right-8">
//         <div className="h-14 w-14 rounded-full border border-[#CB445C] bg-[#CB445C] flex justify-center items-center">
//           <IoIosArrowForward className="text-white text-2xl" />
//         </div>
//       </Link>
//     </DefaultLayout>
//   );
// };

// export default UploadVideoPhotos;

import React, { useState, useRef } from "react";
import DefaultLayout from "../Components/Common/DefaultLayout";
import { IoIosArrowForward } from "react-icons/io";
import { Link } from "react-router-dom";
import { BiImageAdd } from "react-icons/bi";
import { MdOutlinePhotoSizeSelectActual } from "react-icons/md";
import { PiLightbulbFilament } from "react-icons/pi";
import { AiOutlineCloseCircle } from "react-icons/ai";
import CommonHeader from "../Components/Common/CommonHeader";

const UploadVideoPhotos = () => {
  const [uploadedImages, setUploadedImages] = useState(Array(6).fill(null));
  const fileInputRefs = useRef([]);

  const handleDivClick = (index) => {
    fileInputRefs.current[index].click();
  };

  const handleFileChange = (event, index) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const updatedImages = [...uploadedImages];
        updatedImages[index] = reader.result;
        setUploadedImages(updatedImages);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleRemoveImage = (index) => {
    const updatedImages = [...uploadedImages];
    updatedImages[index] = null;
    setUploadedImages(updatedImages);
  };

  return (
    <DefaultLayout>
      <div className="h-10 w-10 rounded-full border border-gray-600 flex justify-center items-center mt-10">
        <MdOutlinePhotoSizeSelectActual className="text-xl" />
      </div>
      
      <div className="flex justify-between items-start gap-1 w-[100%]">
        <CommonHeader title="Pick your videos and photos" />
        <div className="skip">
          <Link to="/done" className="">
            Skip Now
          </Link>
        </div>
      </div>

      <div className="mt-8 grid grid-cols-3 justify-normal items-center gap-4">
        {uploadedImages.map((image, index) => (
          <div
            key={index}
            className="relative h-[93px] flex justify-center items-center rounded-md border border-dashed border-gray-500"
            onClick={() => handleDivClick(index)}
            style={{ cursor: "pointer" }}>
            {image ? (
              <>
                <img
                  src={image}
                  alt={`Uploaded ${index}`}
                  className="h-full w-full object-fill rounded-md"
                />
                <AiOutlineCloseCircle
                  className="absolute top-0 right-0 m-1 text-red-600 text-xl cursor-pointer"
                  onClick={(e) => {
                    e.stopPropagation();
                    handleRemoveImage(index);
                  }}
                />
              </>
            ) : (
              <BiImageAdd className="text-[30px] text-gray-500" />
            )}
            <input
              type="file"
              accept="image/*"
              style={{ display: "none" }}
              ref={(el) => (fileInputRefs.current[index] = el)}
              onChange={(event) => handleFileChange(event, index)}
            />
          </div>
        ))}
      </div>

      <div className="mt-14 relative">
        <PiLightbulbFilament className="absolute top-[-20px] left-[45%] text-[30px]" />
        <div className="border border-gray-400 w-full py-5 px-4 text-[14px] rounded-[10px] flex justify-center items-center text-center">
          Video bring your profile to life, giving others a better sense of who
          you are.
        </div>
      </div>

      <Link to="/done" className="absolute bottom-10 right-8">
        <div className="h-14 w-14 rounded-full border border-[#CB445C] bg-[#CB445C] flex justify-center items-center">
          <IoIosArrowForward className="text-white text-2xl" />
        </div>
      </Link>
    </DefaultLayout>
  );
};

export default UploadVideoPhotos;
