// import React, { useState } from "react";
// import Footer from "../Layouts/Footer";
// import { BsThreeDotsVertical } from "react-icons/bs";
// import post1 from "../assets/explore-img1.jpeg";
// import { FaHeart, FaRegHeart } from "react-icons/fa";
// import profile1 from "../assets/profile1.jpeg";
// import post2 from "../assets/explore-img2.jpeg";
// import post3 from "../assets/explore-img3.jpeg";
// import post1 from "../assets/explore-img1.jpeg";
// import { PiArrowUDownLeftBold } from "react-icons/pi";

// const Explore = () => {
//   const [likedPosts, setLikedPosts] = useState({});

//   const toggleLike = (postId) => {
//     setLikedPosts((prev) => ({
//       ...prev,
//       [postId]: !prev[postId],
//     }));
//   };
//   return (

//     <div className="">
//       <div className="flex justify-around items-center py-6 pt-[2.2rem] mx-2">
//         <div className="h-7 w-7 rounded-full bg-[#222222]">
//           <img
//             src={profile1}
//             alt=""
//             className="h-full w-full object-fill rounded-full"
//           />
//         </div>
//         <div className="px-2 py-0.5 rounded-full bg-black text-white text-[16px]">
//           LoveLanguage{" "}
//         </div>{" "}
//         <div className="px-2 py-0.5 rounded-full border text-black text-[16px]">
//           Gender{" "}
//         </div>{" "}
//         <div className="px-2 py-0.5 rounded-full border text-black text-[16px]">
//           Personality{" "}
//         </div>
//       </div>
//       <div className="h-[760px] overflow-y-auto">
//         <div className="card mx-3 mt-6 pb-10">
//           <div className="flex justify-between items-center">
//             <div className="">
//               <h3 className="text-[18px] font-semibold text-black">
//                 Jhon Robart
//               </h3>
//               <p className="text-gray-500">Active today</p>
//             </div>
//             <div className="flex justify-normal gap-3">
//               <PiArrowUDownLeftBold className="text-xl text-gray-400" />
//               <BsThreeDotsVertical className="text-xl" />
//             </div>
//           </div>
//           <div
//             className="posthome rounded-[12px] mt-3 relative"
//             style={{
//               boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
//             }}>
//             <div
//               className="absolute bottom-5 right-4 border border-[#e4b0ba] bg-white text-gray-600 h-11 w-11 rounded-full flex justify-center items-center"
//               onClick={() => toggleLike("post1")}>
//               {likedPosts["post1"] ? (
//                 <FaHeart className="text-[#CB445C]" />
//               ) : (
//                 <FaRegHeart className="text-[#CB445C]" />
//               )}
//             </div>
//             <img
//               src={post1}
//               alt=""
//               className="w-full h-full object-cover rounded-[12px]"
//             />
//           </div>
//           <div
//             className="writePost rounded-[12px] mt-7 relative"
//             style={{
//               boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
//             }}>
//             <div
//               className="absolute bottom-5 right-4 border border-[#e4b0ba] bg-white text-gray-600 h-11 w-11 rounded-full flex justify-center items-center"
//               onClick={() => toggleLike("post2")}>
//               {likedPosts["post2"] ? (
//                 <FaHeart className="text-[#CB445C]" />
//               ) : (
//                 <FaRegHeart className="text-[#CB445C]" />
//               )}
//             </div>
//             <div className="px-4 py-8">
//               <p className="font-medium px-3">Unusual skill</p>
//               <p className="text-2xl font-bold py-3 px-3">
//                 Jumping high, climbing buildings, shooting web out of wrist
//               </p>
//             </div>
//           </div>
//           <div
//             className="writePost rounded-[12px] mt-7 relative"
//             style={{
//               boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
//             }}>
//             <div
//               className="absolute bottom-5 right-4 border border-[#e4b0ba] bg-white text-gray-600 h-11 w-11 rounded-full flex justify-center items-center"
//               onClick={() => toggleLike("post3")}>
//               {likedPosts["post3"] ? (
//                 <FaHeart className="text-[#CB445C]" />
//               ) : (
//                 <FaRegHeart className="text-[#CB445C]" />
//               )}
//             </div>
//             <img
//               src={post2}
//               alt=""
//               className="w-full h-full object-cover rounded-[12px]"
//             />
//           </div>
//           <div
//             className="writePost rounded-[12px] mt-7 relative"
//             style={{
//               boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
//             }}>
//             <div
//               className="absolute bottom-5 right-4 border border-[#e4b0ba] bg-white text-gray-600 h-11 w-11 rounded-full flex justify-center items-center"
//               onClick={() => toggleLike("post4")}>
//               {likedPosts["post4"] ? (
//                 <FaHeart className="text-[#CB445C]" />
//               ) : (
//                 <FaRegHeart className="text-[#CB445C]" />
//               )}
//             </div>
//             <div className="px-4 py-8">
//               <p className="font-medium px-3">Unusual skill</p>
//               <p className="text-2xl font-bold py-3 px-3">
//                 Jumping high, climbing buildings, shooting web out of wrist
//               </p>
//             </div>
//           </div>
//           <div
//             className="writePost rounded-[12px] mt-7 relative mb-4"
//             style={{
//               boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
//             }}>
//             <div
//               className="absolute bottom-5 right-4 border border-[#e4b0ba] bg-white text-gray-600 h-11 w-11 rounded-full flex justify-center items-center"
//               onClick={() => toggleLike("post5")}>
//               {likedPosts["post5"] ? (
//                 <FaHeart className="text-[#CB445C]" />
//               ) : (
//                 <FaRegHeart className="text-[#CB445C]" />
//               )}
//             </div>
//             <div className="px-4 py-8">
//               <p className="font-medium px-3">Unusual skill</p>
//               <p className="text-2xl font-bold py-3 px-3">
//                 Jumping high, climbing buildings, shooting web out of wrist
//               </p>
//             </div>
//           </div>
//         </div>
//       </div>
//       <div
//         className="absolute bottom-0 left-0 w-[100%]"
//         style={{
//           borderTop: "2px solid #f5c5d8",
//           background: "#fcf3fe",
//           boxShadow: "-5px 5px -5px #dc354578 !important",
//         }}>
//         <Footer />
//       </div>
//     </div>
//   );
// };

// export default Explore;
import React, { useState } from "react";
import Footer from "../Layouts/Footer";
import { BsThreeDotsVertical } from "react-icons/bs";
// import post1 from "../assets/post1.jpeg";
import profile1 from "../assets/profile1.jpeg";
import { FaRegComment } from "react-icons/fa";
import { BiRepost } from "react-icons/bi";
import { MdFavoriteBorder } from "react-icons/md";
import { LuBarChart2 } from "react-icons/lu";
import { FaRegBookmark } from "react-icons/fa6";
import { PiDownloadSimpleBold } from "react-icons/pi";
import { BsDot } from "react-icons/bs";
import post2 from "../assets/explore-img2.jpeg";
import post3 from "../assets/explore-img3.jpeg";
import post1 from "../assets/explore-img1.jpeg";
import ProfileDrawer from "../Components/Modal/ProfileDrawer";

const Explore = () => {
  const [visible, setVisible] = useState(false);
  const posts = [
    {
      id: 1,
      user: {
        name: "Jhon Robart",
        profilePic: profile1,
        postTime: "3:50pm",
      },
      content: {
        text: "Reference site about Lorem Ipsum, giving information on its origins, as well as a random Lipsum generator.",
        image: post3,
      },
      interactions: {
        comments: 232,
        reposts: 232,
        likes: 232,
        views: "20k",
      },
    },
    {
      id: 3,
      user: {
        name: "Jhon Robart",
        profilePic: profile1,
        postTime: "4:00pm",
      },
      content: {
        text: "Just some random thoughts on the day. Reference site about Lorem Ipsum, giving information on its origins, as well as a random Lipsum generator.",
      },
      interactions: {
        comments: 100,
        reposts: 50,
        likes: 150,
        views: "12k",
      },
    },
    {
      id: 2,
      user: {
        name: "Jhon Robart",
        profilePic: profile1,
        postTime: "4:00pm",
      },
      content: {
        text: "Just some random thoughts on the day.",
        image: post1,
      },
      interactions: {
        comments: 100,
        reposts: 50,
        likes: 150,
        views: "23k",
      },
    },
    {
      id: 3,
      user: {
        name: "Jhon Robart",
        profilePic: profile1,
        postTime: "4:00pm",
      },
      content: {
        text: "Just some random thoughts on the day. Reference site about Lorem Ipsum, giving information on its origins, as well as a random Lipsum generator.",
      },
      interactions: {
        comments: 100,
        reposts: 50,
        likes: 150,
        views: "12k",
      },
    },
  ];
  return (
    <div className="h-screen overflow-y-scroll pb-10">
      <div
        className="flex justify-around items-center py-6 pt-[2.2rem] mx-2"
        style={{
          borderBottom: "1px solid #f4c1ca",
        }}>
        <div
          className="h-9 w-9 rounded-full bg-[#222222]"
          onClick={() => setVisible(true)}>
          <img
            src={profile1}
            alt=""
            className="h-full w-full object-fill rounded-full"
          />
        </div>
        <div className="px-2 py-0.5 rounded-full bg-black text-white text-[16px]">
          LoveLanguage
        </div>
        <div className="px-2 py-0.5 rounded-full border text-black text-[16px]">
          Gender
        </div>
        <div className="px-2 py-0.5 rounded-full border text-black text-[16px]">
          Personality
        </div>
      </div>
      <>
        {posts?.map((e, i) => {
          return (
            <div
              className="mx-auto w-[390px] mt-4 pb-3"
              style={{
                borderBottom: "1px solid #f4c1ca",
              }}>
              <div className="flex justify-between items-center">
                <div className=" flex justify-normal items-center gap-2">
                  <div className="h-9 w-9 rounded-full bg-[#222222]">
                    <img
                      src={e?.user?.profilePic}
                      alt=""
                      className="h-full w-full object-fill rounded-full"
                    />
                  </div>
                  <h3 className="text-[18px] font-semibold text-black">
                    {e?.user?.name}
                  </h3>
                  <p className="text-gray-500 flex justify-normal items-center">
                    <BsDot /> {e?.user?.postTime}
                  </p>
                </div>
                <div className="flex justify-normal gap-3">
                  <BsThreeDotsVertical className="text-xl" />
                </div>
              </div>
              <div className="">
                <p className="pl-10 ">{e?.content?.text}</p>
                {e?.content?.image ? (
                  <div className="pl-10 posthome w-[360px] h-[340px] rounded-[12px] mt-3 relative">
                    <img
                      src={e?.content?.image}
                      alt=""
                      className="w-full h-full object-cover rounded-[12px]"
                    />
                  </div>
                ) : (
                  ""
                )}

                <div className="flex justify-around items-center mt-3 w-[360px] ml-auto pb-4">
                  <div className="flex justify-center items-center gap-1">
                    <FaRegComment className=" text-gray-600 text-[18px]" />
                    <p>{e?.interactions?.comments}</p>
                  </div>
                  <div className="flex justify-center items-center gap-1">
                    <BiRepost className=" text-gray-600 text-[24px]" />
                    <p>{e?.interactions?.reposts}</p>
                  </div>
                  <div className="flex justify-center items-center gap-1">
                    <MdFavoriteBorder className=" text-gray-600 text-[18px]" />
                    <p>{e?.interactions?.likes}</p>
                  </div>
                  <div className="flex justify-center items-center gap-1">
                    <LuBarChart2 className=" text-gray-600 text-[18px]" />
                    <p>{e?.interactions?.views}</p>
                  </div>

                  <div className=" text-gray-600 flex justify-normal items-center gap-2">
                    <FaRegBookmark />
                    <PiDownloadSimpleBold />
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </>

      <div
        className="absolute bottom-0 left-0 w-[100%]"
        style={{
          borderTop: "2px solid #f5c5d8",
          background: "#fcf3fe",
          boxShadow: "-5px 5px -5px #dc354578 !important",
        }}>
        <Footer />
      </div>
      <ProfileDrawer visible={visible} setVisible={setVisible} />
    </div>
  );
};

export default Explore;
