import React, { useState } from 'react';
import 'react-phone-input-2/lib/style.css';
import axios from 'axios';
import './otp_verify.css';
import CommonHeader from '../../Components/Common/CommonHeader';

import { FaCheckCircle } from "react-icons/fa";
import { Link } from "react-router-dom";
import { IoIosArrowForward } from "react-icons/io";


const OtpVerify = () => {

  const countryCode = [
    {
      name: "United States",
      code: "+1",
      flag: "https://www.countryflags.com/wp-content/uploads/united-states-of-america-flag-png-large.png",
    },
    {
      name: "Canada",
      code: "+1",
      flag: "https://www.countryflags.com/wp-content/uploads/canada-flag-png-large.png",
    },
    {
      name: "United Kingdom",
      code: "+44",
      flag: "https://www.countryflags.com/wp-content/uploads/united-kingdom-flag-png-large.png",
    },
    {
      name: "Australia",
      code: "+61",
      flag: "https://www.countryflags.com/wp-content/uploads/flag-jpg-xl-9-2048x1024.jpg",
    },
    {
      name: "India",
      code: "+91",
      flag: "https://www.countryflags.com/wp-content/uploads/india-flag-png-large.png",
    },
    {
      name: "Germany",
      code: "+49",
      flag: "https://www.countryflags.com/wp-content/uploads/germany-flag-png-large.png",
    },
    {
      name: "France",
      code: "+33",
      flag: "https://www.countryflags.com/wp-content/uploads/france-flag-png-large.png",
    },
    {
      name: "China",
      code: "+86",
      flag: "https://www.countryflags.com/wp-content/uploads/china-flag-png-large.png",
    },
    {
      name: "Japan",
      code: "+81",
      flag: "https://www.countryflags.com/wp-content/uploads/japan-flag-png-large.png",
    },
    {
      name: "Bangladesh",
      code: "+880",
      flag: "https://www.countryflags.com/wp-content/uploads/flag-jpg-xl-14-1536x922.jpg",
    },
    {
      name: "Brazil",
      code: "+55",
      flag: "https://www.countryflags.com/wp-content/uploads/brazil-flag-png-large.png",
    },
    {
      name: "South Africa",
      code: "+27",
      flag: "https://www.countryflags.com/wp-content/uploads/south-africa-flag-png-large.png",
    },
    {
      name: "Mexico",
      code: "+52",
      flag: "https://www.countryflags.com/wp-content/uploads/mexico-flag-png-large.png",
    },
    {
      name: "Italy",
      code: "+39",
      flag: "https://www.countryflags.com/wp-content/uploads/italy-flag-png-large.png",
    },
    {
      name: "Russia",
      code: "+7",
      flag: "https://www.countryflags.com/wp-content/uploads/russia-flag-png-large.png",
    },
    {
      name: "South Korea",
      code: "+82",
      flag: "https://www.countryflags.com/wp-content/uploads/south-korea-flag-png-large.png",
    },
    {
      name: "Spain",
      code: "+34",
      flag: "https://www.countryflags.com/wp-content/uploads/spain-flag-png-large.png",
    },
    {
      name: "Argentina",
      code: "+54",
      flag: "https://www.countryflags.com/wp-content/uploads/flag-jpg-xl-7-2048x1283.jpg",
    },
    {
      name: "Nigeria",
      code: "+234",
      flag: "https://www.countryflags.com/wp-content/uploads/nigeria-flag-png-large.png",
    },
    {
      name: "Afghanistan",
      code: "+93",
      flag: "https://www.countryflags.com/wp-content/uploads/flag-jpg-xl-1-2048x1365.jpg",
    },
  ];

  // Set default selected country to the United Kingdom
  const defaultCountry = countryCode.find(
    (country) => country.name === "Bangladesh"
  );

  const [selectedCountry, setSelectedCountry] = useState(defaultCountry);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const handleCountrySelect = (country) => {
    setSelectedCountry(country);
    setDropdownOpen(false);
  };
  
  const [phoneNumber, setPhoneNumber] = useState(''); // State to store the phone number
  const [otpCode, setOtpCode] = useState(''); // State to store the OTP code
  const [otpSent, setOtpSent] = useState(false);
  const [error, setError] = useState(null); // State to handle errors
  const [verified, setVerified] = useState(false);
  const [showVerification, setShowVerification] = useState(false);


  const handleSendOtp = () => {
    const phoneSendOtp = process.env.REACT_APP_OTP_SEND_URL + "/twilio/send-otp";
    console.log("OTP Send URL:", phoneSendOtp); // Log the URL to ensure it's correct

    if (!selectedCountry) {
      setError("Please select a country code.");
      return;
    }

    const fullPhoneNumber = `${selectedCountry.code}${phoneNumber}`;

    axios.post(phoneSendOtp, { phone_number: fullPhoneNumber })
    .then(response => {
      if (response.status === 200) {
        setOtpSent(true);
        setShowVerification(true);
        setError(null);
      } else {
        console.error("API Response:", response.data);
        setError("Failed to send OTP.");
      }
    })
    .catch(error => {
      console.error("API Error:", error.response ? error.response.data : error.message);
      setError("Error sending OTP.");
    });
  };

  
  const handleVerifyOtp = () => {
    const fullPhoneNumber = `${selectedCountry.code}${phoneNumber}`;
    const phoneVerifyOtp = process.env.REACT_APP_OTP_SEND_URL + "/twilio/verify-otp";
    axios.post(phoneVerifyOtp, {  phone_number: fullPhoneNumber,  otp_code: otpCode  })
      .then(response => {
        if (response.data) {
          setVerified(true);
        } else {
          setVerified(false);
          setError("OTP verification failed.");
        }
      })
      .catch(error => {
        setVerified(false);
        setError("Error verifying OTP.");
        console.error(error);
      });
  };


  return (

    <div className='mobile_verify_wrap'>
      {!showVerification && (
        <div className='send_otp_wrap'>
          <div className="flex justify-between items-start gap-1 w-[100%]">
            <CommonHeader title={"What's your mobile number?"} />
            <div className="skip">
              <Link to="/password" className="">
                Skip Now
              </Link>
            </div>
          </div>

          {/* Code for sending OTP */}
          <div className="flex justify-normal items-end gap-1">
            {/* Country selection and phone number input */}
            <div
              className="cursor-pointer border-0 border-b border-[#CB445C] py-3"
              onClick={() => setDropdownOpen(!dropdownOpen)}>
              {selectedCountry ? (
                <div className="flex w-full items-center">
                  <img
                    src={selectedCountry.flag}
                    alt={selectedCountry.name}
                    className="h-4 mr-2"
                  />
                  <span>{selectedCountry.code}</span>
                </div>
              ) : (
                <span>Select country code</span>
              )}
            </div>
            {dropdownOpen && (
              <div className="country_dropdown absolute top-10 bg-white border border-gray-300 rounded-md shadow-lg z-10 mt-2">
                {countryCode.map((country) => (
                  <div
                    key={country.code}
                    className="flex items-center p-2 cursor-pointer hover:bg-gray-200"
                    onClick={() => handleCountrySelect(country)}>
                    <img
                      src={country.flag}
                      alt={country.name}
                      className="h-4 mr-2"
                    />
                    <span>{country.name} ({country.code})</span>
                  </div>
                ))}
              </div>
            )}
            <input
              type="number"
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)} // Update phone number state
              placeholder="Enter your phone number"
              className="input rounded-none border-0 border-b border-[#CB445C] w-[100%]"
              required
            />
          </div>
          <div className='otpSent_button_wrap'>
              <p className="mt-2">
                <span className="text-[#CB445C] font-medium">Lovetamin</span> will send
                you a verification code. Message and data rates may apply.
              </p>
              <button className="btn-primary btn-otp" id="btn-send-otp" onClick={handleSendOtp}>
                Send OTP
              </button>
              {otpSent && <p className="message">OTP sent to {phoneNumber}</p>}
          </div>
        </div>
      )}

      {showVerification && !verified && (
        <div className='verify_otp'>
          <CommonHeader title={"Verify OTP"} />
          <input
            className="input-box input rounded-none border-0 border-b border-[#CB445C] w-[100%]"
            type="number"
            value={otpCode}
            onChange={e => setOtpCode(e.target.value)}
            placeholder="Enter OTP"
          />
          <button className="btn-primary btn-otp" id='btn-Verify-otp' onClick={handleVerifyOtp}>
            Verify OTP
          </button>
        </div>
      )}

      {verified && (
        <div className="email-successfully-block">
          <CommonHeader title="Phone number successfully verified!" />
          <div className="otp-verify-message">
            <FaCheckCircle className='icon-verified' /> Phone number verified!
          </div>
          <Link to="/password" className="absolute bottom-10 right-8">
            <div className="h-14 w-14 rounded-full border border-[#CB445C] bg-[#CB445C] flex justify-center items-center">
              <IoIosArrowForward className="text-white text-2xl" />
            </div>
          </Link>
        </div>
      )}
    </div>

  );
};

export default OtpVerify;
