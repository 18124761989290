import React from "react";
import DefaultLayout from "../Components/Common/DefaultLayout";
import { BiFootball } from "react-icons/bi";
import CommonHeader from "../Components/Common/CommonHeader";
import { IoIosArrowForward } from "react-icons/io";
import { Link } from "react-router-dom";

const Hobbies = () => {
  return (
    <DefaultLayout>
      <div className="h-10 w-10 rounded-full border border-gray-600 flex justify-center items-center mt-10">
        <BiFootball className="text-xl" />
      </div>
      
      <div className="flex justify-between items-start gap-1 w-[100%]">
        <CommonHeader title={"What's your Hobbies and interests?"} />
        <div className="skip">
          <Link to="/travel" className="">
            Skip Now
          </Link>
        </div>
      </div>

      <div className="mt-8">
        <input
          type="text"
          className="input rounded-none border-0 border-b border-[#CB445C] w-full px-0"
        />
      </div>

      <Link to="/travel" className="absolute bottom-10 right-8">
        <div className="h-14 w-14 rounded-full border border-[#CB445C] bg-[#CB445C] flex justify-center items-center">
          <IoIosArrowForward className="text-white text-2xl" />
        </div>
      </Link>
    </DefaultLayout>
  );
};

export default Hobbies;
