import React, { useState } from "react";
import { MdOutlineEmail } from "react-icons/md";
import { IoIosArrowForward } from "react-icons/io";
import axios from "axios";
import DefaultLayout from "../Components/Common/DefaultLayout";
import CommonHeader from "../Components/Common/CommonHeader";
import { Link } from "react-router-dom";
import { FaCheckCircle } from "react-icons/fa";

const Email = () => {
  const [email, setEmail] = useState(""); // State to store the email address
  const [otpCode, setOtpCode] = useState(""); // State to store the OTP code
  const [otpSent, setOtpSent] = useState(false); // State to manage OTP sent status
  const [error, setError] = useState(null); // State to handle errors
  const [verified, setVerified] = useState(false); // State to manage verification status

  const sendOtp = () => {
    const emailSendUrl = process.env.REACT_APP_OTP_SEND_URL + "/twilio/send-email-otp";
    // const emailSendUrl = "http://128.199.200.230:8000/twilio/send-email-otp";
      axios.post(emailSendUrl, { email })
      .then(response => {
        setOtpSent(true); // Show OTP verification form
      })
      .catch(error => {
        console.error('Error sending OTP:', error);
        setError('Email already registered!');
      });    
  };

  const verifyOtp = () => {
    const emailVerifyUrl = process.env.REACT_APP_OTP_SEND_URL + "/twilio/verify-email-otp";
    // const emailVerifyUrl = "http://128.199.200.230:8000/twilio/verify-email-otp";
    axios.post(emailVerifyUrl, {
      email,
      otp: otpCode
    })
      .then(response => {
        setVerified(true); // Show success message
      })
      .catch(error => {
        console.error('Error verifying OTP:', error);
        setError('Invalid OTP. Please try again.');
      });
  };

  const handleSendOtp = () => {
    if (!email) {
      setError("Please enter your email"); // Set error message if email is empty
      return;
    }
    setError(null); // Clear any previous errors
    sendOtp(); // Call the actual OTP sending function
  };

  const handleVerifyOtp = () => {
    if (!otpCode) {
      setError("Please enter your OPT"); // Set error message if email is empty
      return;
    }
    setError(null); // Clear any previous errors
    verifyOtp(); // Call the actual OTP sending function
  };


  return (
    <DefaultLayout>
      <div className="h-10 w-10 rounded-full border border-gray-600 flex justify-center items-center mt-10">
        <MdOutlineEmail className="text-xl" />
      </div>

      {!otpSent && (
        <>
          
          <div className="flex justify-between items-start gap-1 w-[100%]">
            <CommonHeader title={"What's your email address?"} />
            {/* <div className="skip">
              <Link to="/mobile-number" className="">
                Skip Now
              </Link>
            </div> */}
          </div>

          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)} // Update email state
            placeholder="Enter your email"
            className="input rounded-none border-0 border-b border-[#CB445C] w-full mt-7 px-0"
            required // Ensure the input is required
          />
          {error && <p className="text-red-500 error-message">{error}</p>}
          <div className="otpSent_button_wrap">
            <p className="mt-2">
              <span className="text-[#CB445C] font-medium">Lovetamin</span> will send
              you a verification code. Message and data rates may apply.
            </p>
            <button
                className="btn-primary btn-otp"
                id="btn-send-otp"
                onClick={handleSendOtp} // Call the handleSendOtp function
              >
                Send OTP
            </button>
          </div>
        </>
      )}

      {otpSent && !verified && (
        <>
          <CommonHeader title="Enter the OTP sent to your email" />
          <input
            type="number"
            value={otpCode}
            onChange={(e) => setOtpCode(e.target.value)} // Update OTP code state
            placeholder="Enter the OTP"
            className="input rounded-none border-0 border-b border-[#CB445C] w-full mt-7 px-0"
          />
          {error && <p className="text-red-500 error-message">{error}</p>}
          <button className="btn-primary btn-otp" id='btn-Verify-otp' onClick={handleVerifyOtp}>Verify OTP</button>
        </>
      )}

      {verified && (
        <div className="email-successfully-block">
          <CommonHeader title="Email successfully verified!" />
          <div className="otp-verify-message"><FaCheckCircle className='icon-verified' /> Your email has been successfully verified!</div>
          <Link to="/mobile-number" className="absolute bottom-10 right-8">
            <div className="h-14 w-14 rounded-full border border-[#CB445C] bg-[#CB445C] flex justify-center items-center">
              <IoIosArrowForward className="text-white text-2xl" />
            </div>
          </Link>
        </div>
        
      )}
    </DefaultLayout>
  );
};

export default Email;
