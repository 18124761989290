import React, { useState } from "react";
import DefaultLayout from "../Components/Common/DefaultLayout";
import CommonHeader from "../Components/Common/CommonHeader";
import { Link } from "react-router-dom";
import { IoIosArrowForward } from "react-icons/io";
import { IoLocationOutline } from "react-icons/io5";
import AddSelectCityModal from "../Components/Modal/AddSelectCityMosal";

const Location = () => {
  return (
    <DefaultLayout>
      <div className="h-10 w-10 rounded-full border border-gray-600 flex justify-center items-center mt-8">
        <IoLocationOutline className="text-xl" />
      </div>
    
      <div className="flex justify-between items-start gap-1 w-[100%]">
        <CommonHeader title={"Where do you live?"} />
        <div className="skip">
          <Link to="/gender" className="">
            Skip Now
          </Link>
        </div>
      </div>


      <div className="mt-8">
        <AddSelectCityModal />
      </div>

      <Link to="/gender" className="absolute bottom-10 right-8">
        <div className="h-14 w-14 rounded-full border border-[#CB445C] bg-[#CB445C] flex justify-center items-center">
          <IoIosArrowForward className="text-white text-2xl" />
        </div>
      </Link>
      
    </DefaultLayout>
  );
};

export default Location;
