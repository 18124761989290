import { createBrowserRouter } from "react-router-dom";
import Main from "../Layouts/Main";
import GettingStartPage from "../Pages/GettingStartPage";
import MobileNumber from "../Pages/MobileNumber";
import Password from "../Pages/Password";
import EmailOTP from "../Pages/EmailOTP";
import BasicInfo from "../Pages/BasicInfo";
import NameFiled from "../Pages/NameFiled";
import EmailWithCheck from "../Pages/EmailWithCheck";
import BirthDate from "../Pages/BirthDate";
import RelationShiptype from "../Pages/RelationShiptype";
import Education from "../Pages/Education";
import CareerField from "../Pages/CareerField";
import Gender from "../Pages/Gender";
import Sexuality from "../Pages/Sexuality";
import Income from "../Pages/Income";
import Email from "../Pages/Email";
import Religious from "../Pages/Religious";
import Political from "../Pages/Political";
import Smoking from "../Pages/Smoking";
import Drinking from "../Pages/Drinking";
import Dietary from "../Pages/Dietary";
import Location from "../Pages/Location";
import Exercise from "../Pages/Exercise";
import UploadVideoPhotos from "../Pages/UploadVideoPhotos";
import Done from "../Pages/Done";
import Hobbies from "../Pages/Hobbies";
import Travels from "../Pages/Travels";
import Pet from "../Pages/Pet";
import DesiredChildren from "../Pages/DesiredChildren";
import Living from "../Pages/Living";
import Communication from "../Pages/Communication";
import LoveLanguage from "../Pages/LoveLanguage";
import Personality from "../Pages/Personality";
import Home from "../Pages/Home";
import CulturalBackground from "../Pages/CulturalBackground";
import Explore from "../Pages/Explore";
import Chats from "../Pages/Chats/Chats";
import ChatDetails from "../Pages/Chats/ChatDetails";
import Match from "../Pages/Match/Match";
import LoginStart from "../Pages/Login/LoginStart";
export const router = createBrowserRouter([
  {
    path: "/",
    element: <Main />,
    children: [
      {
        path: "/",
        element: <GettingStartPage />,
      },
      {
        path: "/email",
        element: <Email />,
      },
      {
        path: "/mobile-number",
        element: <MobileNumber />,
      },
      {
        path: "/password",
        element: <Password />,
      },
      {
        path: "/email-otp",
        element: <EmailOTP />,
      },
      {
        path: "/basic-info",
        element: <BasicInfo />,
      },
      {
        path: "/name",
        element: <NameFiled />,
      },
      {
        path: "/email-check",
        element: <EmailWithCheck />,
      },
      {
        path: "/birthdate",
        element: <BirthDate />,
      },
      {
        path: "/relationship",
        element: <RelationShiptype />,
      },
      {
        path: "/education",
        element: <Education />,
      },
      {
        path: "/career",
        element: <CareerField />,
      },
      {
        path: "/gender",
        element: <Gender />,
      },
      {
        path: "/sexuality",
        element: <Sexuality />,
      },
      {
        path: "/income",
        element: <Income />,
      },
      {
        path: "/religious",
        element: <Religious />,
      },
      {
        path: "/political",
        element: <Political />,
      },
      {
        path: "/smoking",
        element: <Smoking />,
      },
      {
        path: "/drinking",
        element: <Drinking />,
      },
      {
        path: "/dietary",
        element: <Dietary />,
      },
      {
        path: "/location",
        element: <Location />,
      },
      {
        path: "/exercise-frequency",
        element: <Exercise />,
      },
      {
        path: "/hobbies",
        element: <Hobbies />,
      },
      {
        path: "/travel",
        element: <Travels />,
      },
      {
        path: "/hobbies",
        element: <Hobbies />,
      },
      {
        path: "/pet",
        element: <Pet />,
      },
      {
        path: "/living",
        element: <Living />,
      },
      {
        path: "/love-language",
        element: <LoveLanguage />,
      },
      {
        path: "/communication",
        element: <Communication />,
      },
      {
        path: "/desired-children",
        element: <DesiredChildren />,
      },
      {
        path: "/personality",
        element: <Personality />,
      },
      {
        path: "/desired-children",
        element: <DesiredChildren />,
      },
      {
        path: "/upload-photos",
        element: <UploadVideoPhotos />,
      },
      {
        path: "/culturalbg",
        element: <CulturalBackground />,
      },
      {
        path: "/done",
        element: <Done />,
      },
      {
        path: "/home",
        element: <Home />,
      },
      {
        path: "/explore",
        element: <Explore />,
      },
      {
        path: "/match",
        element: <Match />,
      },
      {
        path: "/chat",
        element: <Chats />,
      },
      {
        path: "/chatdetails/:id",
        element: <ChatDetails />,
      },
      {
        path: "/login-start",
        element: <LoginStart />,
      },
    ],
  },
]);
