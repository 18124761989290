import React, { useState } from "react";
import { FaLocationDot } from "react-icons/fa6";


const AddSelectCityModal = () => {
  const [selectedCity, setSelectedCity] = useState(null);
  const cities = [
    {
      name: "New York",
      state: "NY",
      country: "USA",
      population: 8419000,
      area_sq_km: 783.8,
    },
    {
      name: "Tokyo",
      state: "NY",
      country: "USA",
      population: 8419000,
      area_sq_km: 783.8,
    },
    {
      name: "Delhi",
      state: "NY",
      country: "USA",
      population: 8419000,
      area_sq_km: 783.8,
    },
    {
      name: "São Paulo",
      state: "NY",
      country: "USA",
      population: 8419000,
      area_sq_km: 783.8,
    },
    {
      name: "Cairo",
      state: "NY",
      country: "USA",
      population: 8419000,
      area_sq_km: 783.8,
    },
    {
      name: "Mumbai",
      state: "NY",
      country: "USA",
      population: 8419000,
      area_sq_km: 783.8,
    },
    {
      name: "Mexico City",
      state: "NY",
      country: "USA",
      population: 8419000,
      area_sq_km: 783.8,
    },
    {
      name: "Dhaka",
      state: "NY",
      country: "USA",
      population: 8419000,
      area_sq_km: 783.8,
    },
    {
      name: "Tehran",
      state: "NY",
      country: "USA",
      population: 8419000,
      area_sq_km: 783.8,
    },
    {
      name: "Karachi",
      state: "NY",
      country: "USA",
      population: 8419000,
      area_sq_km: 783.8,
    },
    {
      name: "Buenos Aires",
      state: "NY",
      country: "USA",
      population: 8419000,
      area_sq_km: 783.8,
    },
    {
      name: "Istanbul",
      state: "NY",
      country: "USA",
      population: 8419000,
      area_sq_km: 783.8,
    },
    {
      name: "Shanghai",
      state: "NY",
      country: "USA",
      population: 8419000,
      area_sq_km: 783.8,
    },
    {
      name: "Kolkata",
      state: "NY",
      country: "USA",
      population: 8419000,
      area_sq_km: 783.8,
    },
    {
      name: "Lagos",
      state: "NY",
      country: "USA",
      population: 8419000,
      area_sq_km: 783.8,
    },
    {
      name: "Bangalore",
      state: "NY",
      country: "USA",
      population: 8419000,
      area_sq_km: 783.8,
    },
    {
      name: "Paris",
      state: "NY",
      country: "USA",
      population: 8419000,
      area_sq_km: 783.8,
    },
    {
      name: "Jakarta",
      state: "NY",
      country: "USA",
      population: 8419000,
      area_sq_km: 783.8,
    },
    {
      name: "Chennai",
      state: "NY",
      country: "USA",
      population: 8419000,
      area_sq_km: 783.8,
    },
    {
      name: "Seoul",
      state: "NY",
      country: "USA",
      population: 8419000,
      area_sq_km: 783.8,
    },
    {
      name: "Chicago",
      state: "NY",
      country: "USA",
      population: 8419000,
      area_sq_km: 783.8,
    },
    {
      name: "London",
      state: "NY",
      country: "USA",
      population: 8419000,
      area_sq_km: 783.8,
    },
    {
      name: "Lahore",
      state: "NY",
      country: "USA",
      population: 8419000,
      area_sq_km: 783.8,
    },
    {
      name: "Houston",
      state: "CA",
      country: "USA",
      population: 3980000,
      area_sq_km: 1302,
    },
    {
      name: "Los Angeles",
      state: "CA",
      country: "USA",
      population: 3980000,
      area_sq_km: 1302,
    },
    {
      name: "Toronto",
      state: "CA",
      country: "USA",
      population: 3980000,
      area_sq_km: 1302,
    },
    {
      name: "Atlanta",
      state: "CA",
      country: "USA",
      population: 3980000,
      area_sq_km: 1302,
    },
    {
      name: "Chicago",
      state: "IL",
      country: "USA",
      population: 2716000,
      area_sq_km: 589,
    },
    {
      name: "Houston",
      state: "TX",
      country: "USA",
      population: 2328000,
      area_sq_km: 1651,
    },
    {
      name: "Phoenix",
      state: "AZ",
      country: "USA",
      population: 1690000,
      area_sq_km: 1340,
    },
  ];
  
  return (
    <div className="card flex justify-content-center">
      <div className="flex justify-normal items-center flex-wrap gap-3 country_list">
        <select
          className="flex center-align justify-center items-center gap-1 font-semibold rounded-full w-full py-2.5 border border-[#CB445C] text-[#CB445C] mt-5"
        >
          <option>Select Your City <FaLocationDot /></option>
          {cities?.map((city, index) => {
            const isSelected = city.name === selectedCity;
            return (
              <option
                key={index}
                className={`rounded-full border px-2 py-0.5 flex justify-center items-center cursor-pointer ${
                  isSelected ? "bg-[#CB445C] text-white" : ""
                }`}
              >
                {city?.name}
              </option>
            );
          })}
        </select>
        </div>
    </div>
  );
};

export default AddSelectCityModal;
