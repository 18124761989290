import React from "react";
import { Dialog } from "primereact/dialog";
const AddRelationshipModal = ({ visible, setVisible }) => {
  return (
    <div className="card flex justify-content-center">
      <Dialog
        header=""
        className="bg-white p-4 rounded-md"
        visible={visible}
        style={{ width: "80vw" }}
        onHide={() => {
          if (!visible) return;
          setVisible(false);
        }}>
        <div className="form-control py-2">
          <label className="label cursor-pointer">
            <span className="label-text text-[17px]">
              Add your relationship status
            </span>
          </label>
          <input
            type="text"
            className="input rounded-none border-0 border-b border-[#CB445C] w-full mt-1 px-0"
          />
          <div
            className="flex justify-end items-end mt-3"
            onClick={() => setVisible(false)}>
            <button className="bg-[#CB445C] px-2 rounded-[5px] py-0.5 text-white">
              Add
            </button>
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default AddRelationshipModal;
