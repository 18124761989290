import React from "react";
import { MdOutlineEmail } from "react-icons/md";
import { IoIosArrowForward } from "react-icons/io";
import { Link } from "react-router-dom";
import DefaultLayout from "../Components/Common/DefaultLayout";
import CommonHeader from "../Components/Common/CommonHeader";

const EmailWithCheck = () => {
  return (
    <DefaultLayout>
      <div className="h-10 w-10 rounded-full border border-gray-600 flex justify-center items-center mt-10">
        <MdOutlineEmail className="text-xl" />
      </div>

      <CommonHeader title={"What's your email address?"} />
      <div className="mt-8">
        <input
          type="email"
          className="input rounded-none border-0 border-b border-[#CB445C] w-full px-0"
        />
      </div>
      <div className="flex justify-normal items-center gap-2 absolute bottom-10 left-6">
        <input
          type="radio"
          name="radio-1"
          className="custom-radio radio radio-secondary border border-[#CB445C]"
        />
        <p className="text-gray-500  w-[70%]">
          If you don't wish to recieve our marketing communications about out
          products & services check this box please
        </p>
      </div>
      <Link to="/birthdate" className="absolute bottom-10 right-8">
        <div className="h-14 w-14 rounded-full border border-[#CB445C] bg-[#CB445C] flex justify-center items-center">
          <IoIosArrowForward className="text-white text-2xl" />
        </div>
      </Link>
    </DefaultLayout>
  );
};

export default EmailWithCheck;
