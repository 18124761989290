import React from "react";
import { peapleMayknow } from "../../Components/Common/Data";
import { TiUserAddOutline } from "react-icons/ti";

const SuggestionTab = ({ truncateBio }) => {
  return (
    <div className="card mx-6 mt-6 pb-10">
      <p className="text-2xl font-semibold">People you may know</p>
      <div className="mt-4">
        {peapleMayknow?.map((e, i) => (
          <div
            className="rounded-[5px] mt-4 relative shadow border p-4"
            key={i}>
            <div className="absolute bottom-5 right-4 border border-[#e4b0ba] bg-white text-gray-600 h-11 w-11 rounded-full flex justify-center items-center">
              <TiUserAddOutline className="text-xl text-[#CB445C]" />
            </div>
            <div className="flex justify-normal items-center gap-2">
              <div className="h-[50px] w-[50px] rounded-full">
                <img
                  src={e?.image}
                  alt=""
                  className="w-full h-full object-cover rounded-full"
                />
              </div>
              <div>
                <p className="text-[18px] font-medium">{e?.name}</p>
                <p className="text-[16px] font-normal text-gray-500">
                  {truncateBio(e?.bio, 22)}
                </p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SuggestionTab;
