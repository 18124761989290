import React, { useState } from "react";
import { IoIosArrowForward } from "react-icons/io";
import { Link } from "react-router-dom";
import { TbGenderFemme } from "react-icons/tb";
import DefaultLayout from "../Components/Common/DefaultLayout";
import CommonHeader from "../Components/Common/CommonHeader";
import AddGenderModal from "../Components/Modal/AddGenderModal";
import CommonRadio from "../Components/Common/CommonRadio";

const Gender = () => {
  const [visible, setVisible] = useState(false);
  return (
    <DefaultLayout>
      <div className="h-10 w-10 rounded-full border border-gray-600 flex justify-center items-center mt-8">
        <TbGenderFemme className="text-xl" />
      </div>

      <CommonHeader title={"Which Gender best describes you?"} />
      <p className="mt-2">
        <span className="text-[#CB445C] font-medium">Lovetamin</span> users are
        matched based on these three gender groups. You can add more about your
        gender after.
      </p>
      <div className="mt-8 border-0 border-b pb-3">
        <CommonRadio title="Man" />
        <CommonRadio title="Woman" />
        <CommonRadio title="Non-binary" />
        <div className="relative my-6">
          <span
            className="absolute top-[-10px] right-1 h-6 w-6 rounded-full flex justify-center items-center text-white bg-[#cb445c] cursor-pointer"
            onClick={() => setVisible(true)}>
            +
          </span>
          <p className="h-10 w-full border border-dashed text-center py-2 text-gray-500 text-[14px] rounded-md">
            Optional: add more about your gender
          </p>
        </div>
      </div>
      <div className="flex justify-normal items-center gap-1 mt-14">
        <input type="checkbox" className="checkbox custom-radio" />
        <p>Visible on profile</p>
      </div>
      <div className="mt-14">
        <p>
          <span className="text-[#cb445c] font-semibold">Learn more </span>{" "}
          about how we use gender to recommended people.
        </p>
      </div>

      <Link to="/sexuality" className="absolute bottom-10 right-8">
        <div className="h-14 w-14 rounded-full border border-[#CB445C] bg-[#CB445C] flex justify-center items-center">
          <IoIosArrowForward className="text-white text-2xl" />
        </div>
      </Link>
      <AddGenderModal visible={visible} setVisible={setVisible} />
    </DefaultLayout>
  );
};

export default Gender;
