import React, { useState } from "react";
import { IoIosArrowForward } from "react-icons/io";
import { Link } from "react-router-dom";
import { HiOutlineUsers } from "react-icons/hi2";
import DefaultLayout from "../Components/Common/DefaultLayout";
import AddRelationshipModal from "../Components/Modal/AddRelationshipModal";
import CommonHeader from "../Components/Common/CommonHeader";
import CommonRadio from "../Components/Common/CommonRadio";

const RelationShiptype = () => {
  const [visible, setVisible] = useState(false);
  return (
    <DefaultLayout>
      <div className="h-10 w-10 rounded-full border border-gray-600 flex justify-center items-center mt-10">
        <HiOutlineUsers className="text-xl" />
      </div>
      
      <div className="flex justify-between items-start gap-1 w-[100%]">
        <CommonHeader title="What's your desired relationship type?" />
        <div className="skip">
          <Link to="/education" className="">
            Skip Now
          </Link>
        </div>
      </div>

      <div className="mt-8">
        <CommonRadio title="Casual" />
        <CommonRadio title="Serious" />
        <CommonRadio title="Marraige" />

        <div className="relative my-6">
          <span
            className="absolute top-[-10px] right-1 h-6 w-6 rounded-full flex justify-center items-center text-white bg-[#cb445c] cursor-pointer"
            onClick={() => setVisible(true)}>
            +
          </span>
          <p className="h-10 w-full border border-dashed text-center py-2 text-gray-500 text-[14px] rounded-md">
            Optional: add more about your relationship
          </p>
        </div>
      </div>
      <div className="flex justify-normal items-center gap-1 mt-14">
        <input type="checkbox" className="checkbox" />
        <p>Visible on profile</p>
      </div>
      <div className="mt-14">
        <p>
          <span className="text-[#cb445c] font-semibold">Learn more </span>{" "}
          about how we use relationship to recommended people.
        </p>
      </div>
      <Link to="/education" className="absolute bottom-10 right-8">
        <div className="h-14 w-14 rounded-full border border-[#CB445C] bg-[#CB445C] flex justify-center items-center">
          <IoIosArrowForward className="text-white text-2xl" />
        </div>
      </Link>
      <AddRelationshipModal visible={visible} setVisible={setVisible} />
    </DefaultLayout>
  );
};

export default RelationShiptype;
