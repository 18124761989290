import React from "react";
import { IoIosArrowForward } from "react-icons/io";
import { Link } from "react-router-dom";
import { FaRegAddressCard } from "react-icons/fa";
import { FaEye } from "react-icons/fa";
import DefaultLayout from "../Components/Common/DefaultLayout";
import CommonHeader from "../Components/Common/CommonHeader";

const NameFiled = () => {
  return (
    <DefaultLayout>
      <div className="h-10 w-10 rounded-full border border-gray-600 flex justify-center items-center mt-10">
        <FaRegAddressCard className="text-xl" />
      </div>

      <CommonHeader title={"What's your name?"} />
      <div className="mt-8">
        <input
          type="text"
          placeholder="First Name"
          className="input rounded-none border-0 border-b border-[#CB445C] w-full px-0"
        />
        <input
          type="text"
          placeholder="Last Name"
          className="input rounded-none border-0 border-b border-[#CB445C] w-full mt-10 px-0"
        />
      </div>
      <p className="text-gray-500 mt-6">
        Last name is optional, and only shared with matches.{" "}
        <span className="text-[#CB445C] font-semibold">Why?</span>
      </p>
      <p className="text-[18px] flex justify-normal items-center gap-2 absolute bottom-16 left-9">
        <FaEye />
        Always visible on profile
      </p>
      <Link to="/email-check" className="absolute bottom-10 right-8">
        <div className="h-14 w-14 rounded-full border border-[#CB445C] bg-[#CB445C] flex justify-center items-center">
          <IoIosArrowForward className="text-white text-2xl" />
        </div>
      </Link>
    </DefaultLayout>
  );
};

export default NameFiled;
