import React from "react";
import DefaultLayout from "../Components/Common/DefaultLayout";
import { BiFootball } from "react-icons/bi";
import CommonHeader from "../Components/Common/CommonHeader";
import { IoIosArrowForward } from "react-icons/io";
import { Link } from "react-router-dom";
import CommonRadio from "../Components/Common/CommonRadio";

const DesiredChildren = () => {
  return (
    <DefaultLayout>
      <div className="h-10 w-10 rounded-full border border-gray-600 flex justify-center items-center mt-10">
        <BiFootball className="text-xl" />
      </div>
      
      <div className="flex justify-between items-start gap-1 w-[100%]">
        <CommonHeader title={"How many children do you want?"} />
        <div className="skip">
          <Link to="/living" className="">
            Skip Now
          </Link>
        </div>
      </div>

      <div className="mt-8">
        <CommonRadio title="No Child" />
        <CommonRadio title="1" />
        <CommonRadio title="2" />
        <CommonRadio title="3" />
        <CommonRadio title="More" />
      </div>

      <Link to="/living" className="absolute bottom-10 right-8">
        <div className="h-14 w-14 rounded-full border border-[#CB445C] bg-[#CB445C] flex justify-center items-center">
          <IoIosArrowForward className="text-white text-2xl" />
        </div>
      </Link>
    </DefaultLayout>
  );
};

export default DesiredChildren;
