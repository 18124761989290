import React, { useState } from "react";
import { IoIosArrowForward } from "react-icons/io";
import { Link } from "react-router-dom";
import { MdOutlineWorkOutline } from "react-icons/md";
import DefaultLayout from "../Components/Common/DefaultLayout";
import CommonHeader from "../Components/Common/CommonHeader";

const CareerField = () => {
  const [isCurrentlyWorking, setIsCurrentlyWorking] = useState(false);
  const [sections, setSections] = useState([{}]);

  const handleCheckboxChange = () => {
    setIsCurrentlyWorking(!isCurrentlyWorking);
  };

  const handleAddMoreClick = () => {
    if (sections.length < 2) {
      setSections([...sections, {}]);
    }
  };

  return (
    <DefaultLayout>
      <div className="h-10 w-10 rounded-full border border-gray-600 flex justify-center items-center mt-10">
        <MdOutlineWorkOutline className="text-xl" />
      </div>

      <div className="flex justify-between items-start gap-1 w-[100%]">
        <CommonHeader title="What's your career field?" />
        <div className="skip">
          <Link to="/income" className="">
            Skip Now
          </Link>
        </div>
      </div>


      <div className="mt-8">
        <input
          type="text"
          placeholder=""
          className="input rounded-none border-0 border-b border-gray-500 w-full h-8 px-0"
        />
      </div>

      <div className="flex justify-normal items-center gap-1 mt-14">
        <input type="checkbox" className="checkbox" />
        <p>Visible on profile</p>
      </div>

      <Link to="/income" className="absolute bottom-10 right-8">
        <div className="h-14 w-14 rounded-full border border-[#CB445C] bg-[#CB445C] flex justify-center items-center">
          <IoIosArrowForward className="text-white text-2xl" />
        </div>
      </Link>
    </DefaultLayout>
  );
};

export default CareerField;
