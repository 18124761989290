import React, { useState } from "react";
import Footer from "../Layouts/Footer";
import { BsThreeDotsVertical } from "react-icons/bs";
import post1 from "../assets/post1.jpeg";
import profile1 from "../assets/profile1.jpeg";
import { FaRegComment } from "react-icons/fa";
import { BiRepost } from "react-icons/bi";
import { MdFavoriteBorder } from "react-icons/md";
import { LuBarChart2 } from "react-icons/lu";
import { FaRegBookmark } from "react-icons/fa6";
import { PiDownloadSimpleBold } from "react-icons/pi";
import { BsDot } from "react-icons/bs";
import ProfileDrawer from "../Components/Modal/ProfileDrawer";

const Home = () => {
  const [visible, setVisible] = useState(false);
  const posts = [
    {
      id: 1,
      user: {
        name: "Jhon Robart",
        profilePic: profile1,
        postTime: "3:50pm",
      },
      content: {
        text: "Reference site about Lorem Ipsum, giving information on its origins, as well as a random Lipsum generator.",
        image: post1,
      },
      interactions: {
        comments: 232,
        reposts: 232,
        likes: 232,
        views: "20k",
      },
    },
    {
      id: 2,
      user: {
        name: "Jhon Robart",
        profilePic: profile1,
        postTime: "4:00pm",
      },
      content: {
        text: "Just some random thoughts on the day.",
        image: post1,
      },
      interactions: {
        comments: 100,
        reposts: 50,
        likes: 150,
        views: "23k",
      },
    },
    {
      id: 3,
      user: {
        name: "Jhon Robart",
        profilePic: profile1,
        postTime: "4:00pm",
      },
      content: {
        text: "Just some random thoughts on the day. Reference site about Lorem Ipsum, giving information on its origins, as well as a random Lipsum generator.",
      },
      interactions: {
        comments: 100,
        reposts: 50,
        likes: 150,
        views: "12k",
      },
    },
  ];
  return (
    <div className="h-screen overflow-y-scroll pb-10">
      <div
        className="flex justify-around items-center py-6 pt-[2.2rem] mx-2"
        style={{
          borderBottom: "1px solid #f4c1ca",
        }}>
        <div className="drawer-content">
          {/* Page content here */}
          {/* <label htmlFor="my-drawer" className="drawer-button"> */}
          <div
            className="h-9 w-9 rounded-full bg-[#222222]"
            onClick={() => setVisible(true)}>
            <img
              src={profile1}
              alt=""
              className="h-full w-full object-fill rounded-full"
            />
          </div>
          {/* </label> */}
        </div>

        <div className="px-2 py-0.5 rounded-full bg-black text-white text-[16px]">
          Compatible
        </div>
        <div className="px-2 py-0.5 rounded-full border text-black text-[16px]">
          Active Today
        </div>
        <div className="px-2 py-0.5 rounded-full border text-black text-[16px]">
          Near By
        </div>
      </div>
      <>
        {posts?.map((e, i) => {
          return (
            <div
              className="mx-auto w-[390px] mt-4 pb-3"
              style={{
                borderBottom: "1px solid #f4c1ca",
              }}>
              <div className="flex justify-between items-center">
                <div className=" flex justify-normal items-center gap-2">
                  <div className="h-9 w-9 rounded-full bg-[#222222]">
                    <img
                      src={e?.user?.profilePic}
                      alt=""
                      className="h-full w-full object-fill rounded-full"
                    />
                  </div>
                  <h3 className="text-[18px] font-semibold text-black">
                    {e?.user?.name}
                  </h3>
                  <p className="text-gray-500 flex justify-normal items-center">
                    <BsDot /> {e?.user?.postTime}
                  </p>
                </div>
                <div className="flex justify-normal gap-3">
                  <BsThreeDotsVertical className="text-xl" />
                </div>
              </div>
              <div className="">
                <p className="pl-10 ">{e?.content?.text}</p>
                {e?.content?.image ? (
                  <div className="pl-10 posthome w-[360px] h-[340px] rounded-[12px] mt-3 relative">
                    <img
                      src={e?.content?.image}
                      alt=""
                      className="w-full h-full object-cover rounded-[12px]"
                    />
                  </div>
                ) : (
                  ""
                )}

                <div className="flex justify-around items-center mt-3 w-[360px] ml-auto pb-4">
                  <div className="flex justify-center items-center gap-1">
                    <FaRegComment className=" text-gray-600 text-[18px]" />
                    <p>{e?.interactions?.comments}</p>
                  </div>
                  <div className="flex justify-center items-center gap-1">
                    <BiRepost className=" text-gray-600 text-[24px]" />
                    <p>{e?.interactions?.reposts}</p>
                  </div>
                  <div className="flex justify-center items-center gap-1">
                    <MdFavoriteBorder className=" text-gray-600 text-[18px]" />
                    <p>{e?.interactions?.likes}</p>
                  </div>
                  <div className="flex justify-center items-center gap-1">
                    <LuBarChart2 className=" text-gray-600 text-[18px]" />
                    <p>{e?.interactions?.views}</p>
                  </div>

                  <div className=" text-gray-600 flex justify-normal items-center gap-2">
                    <FaRegBookmark />
                    <PiDownloadSimpleBold />
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </>

      <div
        className="absolute bottom-0 left-0 w-[100%]"
        style={{
          borderTop: "2px solid #f5c5d8",
          background: "#fcf3fe",
          boxShadow: "-5px 5px -5px #dc354578 !important",
        }}>
        <Footer />
      </div>

      <ProfileDrawer visible={visible} setVisible={setVisible} />
    </div>
  );
};

export default Home;
