import React, { useState } from "react";
import DefaultLayout from "../Components/Common/DefaultLayout";
import { IoIosArrowForward } from "react-icons/io";
import { FaEye, FaEyeSlash, FaRegEyeSlash } from "react-icons/fa";
import { Link } from "react-router-dom";
import { IoFastFoodOutline } from "react-icons/io5";
import { IoBodyOutline } from "react-icons/io5";
import CommonHeader from "../Components/Common/CommonHeader";
import CommonRadio from "../Components/Common/CommonRadio";

const Exercise = () => {
  const [isVisible, setIsVisible] = useState(false);

  const toggleVisibility = () => {
    setIsVisible((prev) => !prev);
  };

  return (
    <DefaultLayout>
      <div className="h-10 w-10 rounded-full border border-gray-600 flex justify-center items-center mt-10">
        <IoBodyOutline className="text-xl" />
      </div>

      {/* <h2 className="font-semibold text-[30px] mt-3">Exercise in a week?</h2> */}

      <div className="flex justify-between items-start gap-1 w-[100%]">
        <CommonHeader title="Exercise in a week?" />
        <div className="skip">
          <Link to="/hobbies" className="">
            Skip Now
          </Link>
        </div>
      </div>

      <div className="mt-5">
        <CommonRadio title="Everyday" />
        <CommonRadio title="2-4 times" />
        <CommonRadio title="1-2 times" />
        <CommonRadio title="Never" />
      </div>

      <div className="flex justify-normal items-center gap-1 mt-14">
        {isVisible ? (
          <FaEye
            onClick={toggleVisibility}
            className="cursor-pointer text-xl"
          />
        ) : (
          <FaEyeSlash
            onClick={toggleVisibility}
            className="cursor-pointer text-xl"
          />
        )}
        <p>{isVisible ? "Visible on profile" : "Hidden on profile"}</p>
      </div>

      <Link to="/hobbies" className="absolute bottom-10 right-8">
        <div className="h-14 w-14 rounded-full border border-[#CB445C] bg-[#CB445C] flex justify-center items-center">
          <IoIosArrowForward className="text-white text-2xl" />
        </div>
      </Link>
    </DefaultLayout>
  );
};

export default Exercise;
