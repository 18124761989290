import React from "react";
import { Sidebar } from "primereact/sidebar";
import profile1 from "../../assets/profile1.jpeg";
import { FaRegUser } from "react-icons/fa";
import { TbPremiumRights } from "react-icons/tb";
import { LuClipboardList } from "react-icons/lu";
import { FaRegBookmark } from "react-icons/fa6";
import { AiOutlineThunderbolt } from "react-icons/ai";
import { LiaMoneyCheckSolid } from "react-icons/lia";
import { LuArrowUpRightSquare } from "react-icons/lu";
import { RiSuitcaseLine } from "react-icons/ri";
import { FiSettings } from "react-icons/fi";
import { TbLogout } from "react-icons/tb";

const ProfileDrawer = ({ visible, setVisible }) => {
  return (
    <Sidebar
      visible={visible}
      onHide={() => setVisible(false)}
      className="bg-white p-4">
      <div>
        <div
          className="h-9 w-9 rounded-full bg-[#222222] mt-1"
          onClick={() => setVisible(true)}>
          <img
            src={profile1}
            alt=""
            className="h-full w-full object-fill rounded-full"
          />
        </div>
        <p className="font-semibold text-[18px] mt-1">Jhon Robart</p>
        <p className="text-gray-500">@jrobert</p>
        <p className="font-normal text-gray-500 mt-2">
          <span className="font-medium text-black pr-1"> 14</span>Following
          <span className="font-medium text-black pr-1 pl-3"> 5</span>Followers
        </p>
      </div>

      <div className="mt-10">
        <div className="flex justify-start items-center gap-3">
          <FaRegUser className="text-xl font-bold" />{" "}
          <p className="text-xl font-semibold">Profile</p>
        </div>
        {/* <div className="flex justify-start items-center gap-3 mt-4">
          <TbPremiumRights className="text-2xl font-bold" />{" "}
          <p className="text-xl font-semibold">Premium</p>
        </div>
        <div className="flex justify-start items-center gap-3 mt-4">
          <LuClipboardList className="text-2xl font-bold" />{" "}
          <p className="text-xl font-semibold">Lists</p>
        </div>
        <div className="flex justify-start items-center gap-3 mt-4">
          <FaRegBookmark className="text-2xl font-bold" />{" "}
          <p className="text-xl font-semibold">BookMarks</p>
        </div>
        <div className="flex justify-start items-center gap-3 mt-4">
          <AiOutlineThunderbolt className="text-2xl font-bold" />{" "}
          <p className="text-xl font-semibold">Verified orgs</p>
        </div>
        <div className="flex justify-start items-center gap-3 mt-4">
          <LiaMoneyCheckSolid className="text-2xl font-bold" />{" "}
          <p className="text-xl font-semibold">Monetization</p>
        </div>
        <div className="flex justify-start items-center gap-3 mt-4">
          <LuArrowUpRightSquare className="text-2xl font-bold" />{" "}
          <p className="text-xl font-semibold">Ads</p>
        </div>
        <div className="flex justify-start items-center gap-3 mt-4">
          <RiSuitcaseLine className="text-2xl font-bold" />{" "}
          <p className="text-xl font-semibold">Jobs</p>
        </div> */}
        <div className="flex justify-start items-center gap-3 mt-4">
          <FiSettings className="text-2xl font-bold" />{" "}
          <p className="text-xl font-semibold">Setting & Privacy</p>
        </div>
        <div className="flex justify-start items-center gap-3 mt-4">
          <TbLogout className="text-2xl font-bold" />{" "}
          <p className="text-xl font-semibold">Log out</p>
        </div>
      </div>
    </Sidebar>
  );
};

export default ProfileDrawer;
