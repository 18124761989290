import React, { useState } from "react";
import { FiPhoneCall } from "react-icons/fi";
import DefaultLayout from "../Components/Common/DefaultLayout";
import OtpVerify from "./MobileNumberVerify/OtpVerify";
import { IoIosArrowForward } from "react-icons/io";
import { Link } from "react-router-dom";

const MobileNumber = () => {
  
  return (
    <DefaultLayout>
      <div className="h-10 w-10 rounded-full border border-gray-600 flex justify-center items-center mt-10">
        <FiPhoneCall className="text-xl" />
      </div>

      <div className="opt_verify_wrapper">
        <OtpVerify />
      </div>
    
    </DefaultLayout>
  );
};

export default MobileNumber;
