import React from "react";
import { Link, useLocation } from "react-router-dom";
import { PiHouse } from "react-icons/pi";
import {
  BsSearchHeart,
  BsSearchHeartFill,
  BsChatHeart,
  BsChatHeartFill,
} from "react-icons/bs";
import { LuUsers2 } from "react-icons/lu";
import { HiMiniUsers } from "react-icons/hi2";
import { GoHomeFill } from "react-icons/go";

const Footer = () => {
  const location = useLocation();
  const { pathname } = location;

  return (
    <div
      className="flex justify-around items-center bg-white shadow-md py-4 absolute bottom-0 left-0 w-[100%]"
      style={{
        borderTop: "1px solid #f4c1ca",
      }}>
      <div>
        <Link to="/home">
          {pathname === "/home" ? (
            <GoHomeFill className="text-2xl text-[#CB445C]" />
          ) : (
            <PiHouse className="text-2xl text-[#CB445C]" />
          )}
        </Link>
      </div>
      <div>
        <Link to="/explore">
          {pathname === "/explore" ? (
            <BsSearchHeartFill className="text-2xl text-[#CB445C]" />
          ) : (
            <BsSearchHeart className="text-2xl text-[#CB445C]" />
          )}
        </Link>
      </div>
      <div>
        <Link to="/match">
          {pathname === "/match" ? (
            <HiMiniUsers className="text-2xl text-[#CB445C]" />
          ) : (
            <LuUsers2 className="text-2xl text-[#CB445C]" />
          )}
        </Link>
      </div>
      <div>
        <Link to="/chat">
          {pathname === "/chat" ? (
            <BsChatHeartFill className="text-2xl text-[#CB445C]" />
          ) : (
            <BsChatHeart className="text-2xl text-[#CB445C]" />
          )}
        </Link>
      </div>
    </div>
  );
};

export default Footer;
