import React from "react";
import DefaultLayout from "../Components/Common/DefaultLayout";
import { TbChartAreaLine } from "react-icons/tb";
import { Link } from "react-router-dom";
import { IoIosArrowForward } from "react-icons/io";
import CommonHeader from "../Components/Common/CommonHeader";

const Political = () => {
  return (
    <DefaultLayout>
      <div className="h-10 w-10 rounded-full border border-gray-600 flex justify-center items-center mt-10">
        <TbChartAreaLine className="text-xl" />
      </div>

      <div className="flex justify-between items-start gap-1 w-[100%]">
        <CommonHeader title="What's your political views?" />
        <div className="skip">
          <Link to="/smoking" className="">
            Skip Now
          </Link>
        </div>
      </div>

      <div className="mt-8">
        <textarea
          type="text"
          placeholder="Type your point of political views"
          className="textarea px-1.5 rounded-md h-56 border border-[#cb445c] w-full"
        />
      </div>

      <Link to="/smoking" className="absolute bottom-10 right-8">
        <div className="h-14 w-14 rounded-full border border-[#CB445C] bg-[#CB445C] flex justify-center items-center">
          <IoIosArrowForward className="text-white text-2xl" />
        </div>
      </Link>
    </DefaultLayout>
  );
};

export default Political;
